<template>
  <div class="flex justify-end mb-5">
    <v-btn
      variant="plain"
      color="error"
      @click="click"
    >
      {{ $values.labels.remove }}
    </v-btn>
  </div>
</template>

<script>
export default {
  emits: ['click'],
  setup(_, { emit }) {
    return {
      click: e => emit('click', e)
    }
  }
}
</script>
