<template>
  <v-divider
    :class="classes"
  />
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    spaced: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const classes = computed(() => ({
      'border-opacity-100': true,
      'mb-6': props.spaced
    }))

    return {
      classes
    }
  }
}
</script>
