// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@keyframes rotate-1c2fa47f {
0% {
    transform: rotate(0);
}
100% {
    transform: rotate(360deg);
}
}
.loading-icon[data-v-1c2fa47f] {
  width: 13px;
}
.loading-icon img[data-v-1c2fa47f] {
  width: 100%;
  animation: 1s infinite rotate-1c2fa47f linear;
}
`, "",{"version":3,"sources":["webpack://./resources/client/components/Loading.vue"],"names":[],"mappings":";AAOA;AACE;IACE,oBAAoB;AACtB;AACA;IACE,yBAAyB;AAC3B;AACF;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,6CAAoC;AACtC","sourcesContent":["<template>\n  <span class=\"loading-icon\">\n    <img src=\"dist/loading.png\">\n  </span>\n</template>\n\n<style scoped>\n@keyframes rotate {\n  0% {\n    transform: rotate(0);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n.loading-icon {\n  width: 13px;\n}\n.loading-icon img {\n  width: 100%;\n  animation: 1s infinite rotate linear;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
