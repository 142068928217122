import { toUrlByApiV1, sendPutWithJson, sendPost, sendGet, sendPostWithJson } from '.'

export const fetchMaterials = () =>
  sendGet(toUrlByApiV1('materials'))

export const getMaterialsRequiredFields = () =>
  sendGet(toUrlByApiV1('materials/required-fields'))

export const fetchCurrentMaterial = () =>
  sendGet(toUrlByApiV1('materials/uncompleted-or-new'))

export const applyMaterial = ({ material, is }) => 
  sendPutWithJson(toUrlByApiV1('materials'), material, is && { is })

export const applyMaterialAgreement = ({ material, is }) =>
  sendPostWithJson(toUrlByApiV1('materials/apply-agreement'), material, is && { is })

export const completeMaterial = ({ material }) =>
  sendPost(toUrlByApiV1(`materials/${material.id}/complete`))
