<template>
  <div class="w-full h-full grid grid-rows-min-full grid-cols-1 lg:grid-cols-2 lg:grid-rows-1 overflow-hidden">
    <!-- Background -->
    <div
      class="bg-blue-50 h-28 lg:h-full"
      style="background-image: url('dist/background.jpg'); background-size: cover"
    >
      <!-- Logo -->
      <img
        class="absolute top-8 left-8"
        src="dist/logo_osnova.svg"
      >
    </div>

    <!-- Workspace -->
    <div
      style="display: grid; grid-template-rows: 8fr 2fr"
      class="overflow-y-scroll py-8"
    >
      <!-- Content -->
      <div
        class="w-5/6"
        style="align-self: center; justify-self: center"
      >
        <!-- Header -->
        <page-header
          :title="title"
          :subtitle="subtitle"
          class="mb-2"
        />

        <!-- Custom -->
        <slot />
      </div>

      <!-- Footer -->
      <div
        v-if="!withoutFooter"
        class="flex justify-center px-10 pt-8"
      >
        <page-footer />
      </div>
    </div>
  </div>
</template>

<script>
import PageFooter from './PageFooter.vue'
import PageHeader from './PageHeader.vue'

export default {
  components: { PageHeader, PageFooter },
  props: {
    title: String,
    subtitle: String,
    withoutFooter: Boolean
  },
  setup() {
    
  }
}
</script>
