import { defineStore } from 'pinia'
import { ref } from 'vue'
import { applyMaterial, applyMaterialAgreement, completeMaterial, fetchCurrentMaterial, fetchMaterial, getMaterialsRequiredFields } from '../api/materials'

export const useMaterialStore = defineStore('materials', () => {
  const material = ref({})

  return {
    material,

    fetchMaterial: ({ id }) => fetchMaterial({ id }),
    fetchCurrentMaterial: () => fetchCurrentMaterial(),
    fetchMaterialsRequiredFields: () => getMaterialsRequiredFields(),

    applyMaterial: ({ material, is }) => applyMaterial({ material, is }),
    applyMaterialAgreement: ({ material }) => applyMaterialAgreement({ 
      material, 
      is: ['agreement_status']
    }),

    completeMaterial: ({ material }) => completeMaterial({ material })
  }
})
