<template>
  <div>
    <!-- About background -->
    <p class="text-xl font-bold mb-4">
      {{ $values.labels.about_background }}
    </p>

    <!-- Backgrounds -->
    <div>
      <!-- List -->
      <div
        v-for="(item, i) in modelValue.backgrounds"
        :key="item.id"
      >
        <!-- Name -->
        <main-field
          :model-value="item.name"
          :name="`backgrounds.${i}.name`"
          label="background_name"
          :errors="errors"
          :required="isRequiredField('backgrounds.*.name')"
          @update:model-value="x => updateNestedField('name', x, 'backgrounds', item)"
        />

        <div class="flex space-x-5">
          <!-- Result -->
          <main-field
            :model-value="item.result"
            class="basis-full"
            :name="`backgrounds.${i}.result`"
            label="background_result" 
            :errors="errors"
            :required="isRequiredField('backgrounds.*.result')"
            @update:model-value="x => updateNestedField('result', x, 'backgrounds', item)"
          />

          <!-- At -->
          <main-field
            :model-value="item.at"
            class="basis-1/3"
            :name="`backgrounds.${i}.at`"
            label="background_at" 
            :errors="errors"
            :required="isRequiredField('backgrounds.*.at')"
            @update:model-value="x => updateDate(x, item)"
          />
        </div>

        <!-- Remove -->
        <div class="flex justify-end mb-5">
          <v-btn
            v-if="modelValue.backgrounds.length > 1"
            variant="plain"
            color="error"
            @click="removeNestedItem('backgrounds', item)"
          >
            {{ $values.labels.remove }}
          </v-btn>
        </div>
      </div> 

      <!-- Append -->
      <v-btn
        class="w-full mb-6"
        @click="appendNestedItem('backgrounds')"
      >
        {{ $values.labels.add }}
      </v-btn>
    </div>

    <v-divider
      class="mb-4 border-opacity-100"
    />

    <!-- About Languages -->
    <p class="text-xl font-bold mb-4">
      {{ $values.labels.about_languages }}
    </p>

    <!-- Languages -->
    <div>
      <!-- List -->
      <div
        v-for="(item, i) in modelValue.languages"
        :key="item.id"
      >
        <!-- Name -->
        <main-select
          :model-value="item.name"
          :name="`languages.${i}.name`"
          label="languages_name"
          :errors="errors"
          returnObject
          :required="isRequiredField('languages.*.name')"
          :items="$values.enums.languages"
          @update:model-value="x => updateNestedField('name', x, 'languages', item)"
        />
        <!-- Status -->
        <main-select
          :model-value="item.status"
          :name="`languages.${i}.status`"
          label="languages_status"
          :errors="errors"
          :required="isRequiredField('languages.*.status')"
          returnObject
          :disabled="modelValue.languages[i].name == 'Не владею'"
          :items="$values.enums.languages_statuses"
          @update:model-value="x => updateNestedField('status', x, 'languages', item)"
        />
        <!-- Remove -->
        <div class="flex justify-end mb-5">
          <v-btn
            v-if="modelValue.languages.length > 1"
            variant="plain"
            color="error"
            @click="removeNestedItem('languages', item)"
          >
            {{ $values.labels.remove }}
          </v-btn>
        </div>
      </div> 

      <!-- Append -->
      <v-btn
        class="w-full mb-6"
        @click="appendNestedItem('languages')"
      >
        {{ $values.labels.add }}
      </v-btn>
    </div>

    <v-divider
      class="mb-4 border-opacity-100"
    />
      
    <!-- About recommendations -->
    <p class="text-xl font-bold mb-4">
      {{ $values.labels.about_recommendation }}
    </p>

    <div>
      <div
        v-for="(item, i) in modelValue.recommendations"
        :key="item.id"
      >
        <!-- Position -->
        <main-field
          :model-value="item.position"
          :name="`recommendations.${i}.position`"
          label="recommendation_position"
          :errors="errors"
          :required="isRequiredField('recommendations.*.position')"
          @update:model-value="x => updateNestedField('position', x, 'recommendations', item)"
        />

        <!-- Organization -->
        <main-field
          :model-value="item.organization"
          :name="`recommendations.${i}.organization`"
          label="recommendation_organization"
          :errors="errors"
          :required="isRequiredField('recommendations.*.organization')"
          @update:model-value="x => updateNestedField('organization', x, 'recommendations', item)"
        />

        <!-- Contact -->
        <main-field
          :model-value="item.contact"
          :name="`recommendations.${i}.contact`"
          label="recommendation_contact"
          :errors="errors"
          :required="isRequiredField('recommendations.*.contact')"
          @update:model-value="x => updateNestedField('contact', x, 'recommendations', item)"
        />

        <!-- Remove -->
        <div class="flex justify-end mb-5">
          <v-btn
            variant="plain"
            color="error"
            @click="removeNestedItem('recommendations', item)"
          >
            {{ $values.labels.remove }}
          </v-btn>
        </div>
      </div>

      <!-- Append -->
      <v-btn
        class="w-full mb-6"
        @click="appendNestedItem('recommendations')"
      >
        {{ $values.labels.add }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { key } from '../utils'

import MainField from '../components/MainField.vue'
import MainSelect from '../components/MainSelect.vue'

export default {
  components: {
    MainField,
    MainSelect
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    requiredFields: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update-field', 'update-nested-field', 'append-nested-item', 'remove-nested-item'],
  setup(props, { emit }) {
    const updateNestedField = (k, v, f, x) => emit('update-nested-field', [k, v, f, x])
    return {
      isRequiredField: k => !!props.requiredFields[k],
      updateField: (k, v) => emit('update-field', [k, v]),
      updateNestedField: updateNestedField,
      removeNestedItem: (f, x) => emit('remove-nested-item', [f, x]),
      appendNestedItem: f => emit('append-nested-item', [f, { id: key() }]),
      updateDate: (x, item) => {
        const date = x.replace(/([^\d]+)/gi, '')
        updateNestedField('at', date, 'backgrounds', item)
      }
    }
  }
}
</script>
