<template>
  <div>
    <p class="font-bold text-3xl">
      {{ title }}
    </p>
    <p class="text-gray-500">
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String
  },
  setup() {
    
  }
}
</script>
