<template>
  <div class="flex justify-center items-center bg-gray-100 w-full h-full">
    <!-- To home -->
    <v-btn
      class="absolute top-4 right-4"
      variant="plain"
      @click="returnToHome"
    >
      {{ $values.labels.returnToHome }}
    </v-btn>

    <!-- Login form -->
    <div class="w-1/4 space-y-2 bg-white rounded p-8 shadow">
      <!-- Login -->
      <main-field 
        v-model="value.login"
        name="login"
        label="login"
        :errors="value.messages"
      />

      <!-- Password -->
      <main-field 
        v-model="value.password"
        type="password"
        name="password"
        label="password"
        :errors="value.messages"
      />

      <!-- Actions -->
      <div class="flex justify-end">
        <!-- Confirm -->
        <v-btn
          color="primary"
          @click="login"
        >
          {{ $values.labels.continue }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from 'stores/auth'

import MainField from 'components/MainField.vue'

export default {
  components: {
    MainField
  },
  setup() {
    const router = useRouter()

    const value = reactive({})

    const { loginAsChief } = useAuthStore()

    return {
      value,

      login: () => loginAsChief({
        login: value.login,
        password: value.password
      })
        .then(() => router.push({ name: 'management' }))
        .catch(({ messages }) => value.messages = messages),

      returnToHome: () => router.push({ name: 'login' })
    }
  }
}
</script>
