// Common

import { createApp } from 'vue'
import Application from './views/Application.vue'

const application = createApp(Application)

// Values

import values from './values'

application.config.globalProperties.$values = values

// Router

import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes
})

values.environment.APP_ENV === 'local' && router.beforeEach((to, from) => {
  console.log({ to, from })

  return true
})

application.use(router)

// Store

import { createPinia } from 'pinia'

const pinia = createPinia()

application.use(pinia)

// Api

import { registerMiddleware, send, useAccessToken } from './api'
import { refresh } from './api/auth'

values.environment.APP_ENV === 'local' && registerMiddleware((req, res) => console.log({ req, res }))

registerMiddleware((req, res) => 
  res.status === 401 ? refresh().then(({ access_token, redirect_as } = {}) => {
    const auth = useAuthStore()

    if (access_token) {
      useAccessToken(access_token)
      return send(req)
    } else {
      auth.clearCredentials()

      if (!redirect_as || redirect_as === 'user')
        throw router.push({ name: 'login' })

      if (redirect_as === 'chief')
        throw router.push({ name: 'management-login' })
    }
  }) : res
)

// Vuetify

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { ru } from 'vuetify/locale'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

const vuetify = createVuetify({ 
  components, 
  directives, 
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  locale: {
    locale: 'ru',
    messages: { ru }
  },
  theme: {
    defaultTheme: 'custom',
    themes: {
      custom: {
        colors: {
          primary: '#142654'
        }
      }
    }
  }
})

application.use(vuetify)

application.mount('#application')

// Debug

application.config.performance = true

// Styles

import './index.css'
import { useAuthStore } from './stores/auth'

