<template>
  <div class="space-y-2">
    <p class="text-xl font-bold">
      {{ $values.labels.about_document }}
    </p>
    <div class="grid grid-cols-2 space-x-5" v-if="russianCitizenship">
      <main-field 
        :model-value="modelValue.passport_seria"
        name="passport_seria"
        label="passport_seria"
        mask="## ##"
        :disabled="disablePassport"
        :errors="errors"
        :required="isRequiredField('passport_seria')"
        @update:model-value="x => updateField('passport_seria', x)"
      />

      <main-field 
        :model-value="modelValue.passport_number"
        name="passport_number"
        label="passport_number"
        mask="######"
        :disabled="disablePassport"
        :errors="errors"
        :required="isRequiredField('passport_number')"
        @update:model-value="x => updateField('passport_number', x)"
      />
    </div>

    <div class="grid space-x-5" :class="{'grid-cols-2': !russianCitizenship}">
      <main-field 
        v-if="!russianCitizenship"
        :model-value="modelValue.passport_code"
        name="passport_code"
        label="passport_code"
        :disabled="disablePassport"
        :errors="errors"
        :required="isRequiredField('passport_code')"
        @update:model-value="x => updateField('passport_code', x)"
      />

      <main-field 
        :model-value="modelValue.passport_at"
        name="passport_at"
        label="passport_at"
        type="date"
        :errors="errors"
        :required="isRequiredField('passport_at')"
        @update:model-value="x => updateField('passport_at', x)"
      />
    </div>

    <main-field 
      :model-value="modelValue.passport_by"
      name="passport_by"
      label="passport_by"
      :errors="errors"
      :required="isRequiredField('passport_by')"
      @update:model-value="x => updateField('passport_by', x)"
    />
    <main-field 
      :model-value="modelValue.passport_subdivision_code"
      name="passport_subdivision_code"
      label="passport_subdivision_code"
      :errors="errors"
      mask="###-###"
      returnMasked
      :required="russianCitizenship"
      @update:model-value="x => updateField('passport_subdivision_code', x)"
    />

    <v-divider
      class="mb-4 border-opacity-100"
    />

    <div class="grid grid-cols-2 space-x-5">
      <main-field 
        :model-value="modelValue.document_sns"
        name="document_sns"
        label="document_sns"
        mask="###-###-### ##"
        :errors="errors"
        :required="russianCitizenship"
        @update:model-value="x => updateField('document_sns', x)"
      />

      <main-field 
        :model-value="modelValue.document_tin"
        name="document_tin"
        label="document_tin"
        :errors="errors"
        :disabled="disableTin"
        :required="russianCitizenship"
        @update:model-value="x => updateField('document_tin', x)"
      />
    </div>

    <div class="flex justify-end">
      <v-btn
        variant="plain"
        size="x-small"
        href="https://service.nalog.ru/static/personal-data.html?svc=inn&from=%2Finn.do"
        target="_blank"
      >
        <span class="underline underline-offset-2">{{ $values.labels.document_tin_link }}</span>
      </v-btn>
    </div>
  </div> 
</template>

<script>
import MainField from '../components/MainField.vue'
import { computed, watch, onMounted } from 'vue'
import { useValues } from '../utils'

export default {
  components: { MainField },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    requiredFields: {
      type: Object,
      default: () => ({})
    },
    disableTin: {
      type: Boolean,
      default: false
    },
    disablePassport: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update-field'],
  setup(props, { emit }) {
    const $values = useValues()
    const russianCitizenship = computed(() => props.modelValue.person_citizenship == $values.enums.person_citizenship[0])
    const updateField = (k, v) => emit('update-field', [k, v])
    const fixPassportData = function () {
      if (russianCitizenship.value) {
        props.modelValue.passport_code = ''
        updateField('passport_code', '')
      } else {
        props.modelValue.passport_seria = ''
        updateField('passport_seria', '')
        props.modelValue.passport_number = ''
        updateField('passport_number', '')
      }
    }
    watch(russianCitizenship, x => {
      fixPassportData()
    })
    onMounted (() => {
      fixPassportData()
    })
    return {
      russianCitizenship,
      fixPassportData,
      isRequiredField: k => !!props.requiredFields[k],
      updateField,
    }
  }
}
</script>
