<template>
  <main-layout
    :title="$values.labels.agreement"
    :subtitle="$values.labels.about_agreement"
  >
    <!-- Content -->
    <agreement-content
      class="overflow-y-scroll mb-4"
      style="height: 50vh;"
    />

    <!-- Accept -->
    <v-checkbox
      v-model="accepted"
      :label="$values.labels.accept_agreement"
    />

    <!-- Next -->
    <v-btn
      :disabled="!accepted"
      rounded="pill"
      color="primary"
      class="w-full"
      @click="next"
    >
      {{ $values.labels.continue }}
    </v-btn>
  </main-layout>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useMaterialStore } from '../stores/materials'

import MainLayout from '../components/MainLayout.vue'
import AgreementContent from '../components/AgreementContent.vue'

export default {
  components: { MainLayout, AgreementContent },
  setup() {
    const router = useRouter()

    const materialStore = useMaterialStore()

    const accepted = ref(false)

    return {
      accepted,

      next: () => {
        materialStore.applyMaterialAgreement({
          material: {
            agreement_status: true
          }
        }).then(() => router.push({ name: 'materials', query: { step: 1 } }))
      }
    }
  }
}
</script>
