<template>
  <v-card>
    <v-layout class="h-full">
      <!-- Navigation -->
      <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
        @click="rail = false"
      >
        <v-list-item
          :title="me.login"
          nav
        >
          <template #prepend>
            <v-avatar color="primary">
              <v-icon icon="mdi-account-circle" />
            </v-avatar>
          </template>
          <template #append>
            <v-btn
              variant="text"
              icon="mdi-chevron-left"
              @click.stop="rail = !rail"
            />
          </template>
        </v-list-item>

        <v-divider
          class="border-opacity-50"
        />

        <div>
          <!-- Navigation menu -->
          <v-list
            density="compact"
            nav
          >
            <v-list-item
              v-for="item in navigationItems"
              :key="item.id"
              :prepend-icon="item.icon"
              :title="item.title"
              :value="item.value"
              :active="selected(item.value)"
              @click="goTo(item.value)"
            />
          </v-list>

          <v-divider
            class="border-opacity-50"
          />

          <!-- Other actions -->
          <v-list
            density="compact"
            nav
          >
            <v-list-item
              v-for="item in actionItems"
              :key="item.id"
              :prepend-icon="item.icon"
              :title="item.title"
              :value="item.value"
              @click="item.act"
            />
          </v-list>
        </div>
      </v-navigation-drawer>

      <!-- Body -->
      <v-main
        class="bg-gray-100 overflow-y-scroll"
      >
        <div class="p-4 box-border h-full">
          <router-view />
        </div>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { key, useValues } from 'utils'

import { useAuthStore } from 'stores/auth'

export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const $values = useValues()

    const auth = useAuthStore()

    const drawer = ref(true)
    const rail = ref(true)

    const me = computed(() => auth.me)

    onMounted(() => {
      auth.fetchMeAsChief()
    })

    return {
      drawer,
      rail,

      me,

      navigationItems: [
        { id: key(), title: $values.labels.materials, icon: 'mdi-format-list-bulleted', value: 'management-materials' },
        { id: key(), title: $values.labels.users, icon: 'mdi-account', value: 'management-users' }
      ],

      actionItems: [
        { 
          id: key(), 
          title: $values.labels.logout, 
          icon: 'mdi-logout', 
          value: 'management-materials', 
          act: () => {
            auth.logoutAsChief().then(() => router.push({ name: 'management-login' }))
          } 
        }
      ],

      goTo: name => router.push({ name }),
      selected: name => route.name === name
    }
  }
}
</script>
