<template>
  <div class="address-field">
    <main-field
      :model-value="modelValue"
      :type="type"
      :name="name"
      :label="label"
      :errors="errors"
      :readonly="readonly"
      :mask="mask"
      :returnMasked="returnMasked"
      :eager="eager"
      :disabled="disabled"
      :required="required"
      @update:model-value="x => update(x)"
      @update:focused="onfocused"
    />
    <div class="address-field__wrapper" v-if="focused && list.length">
      <div class="address-field__list">
        <div
          class="address-field__item"
          v-for="(item, i) in list"
          :key="i"
          @mousedown="update(item.value)"
        >
          {{ item.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import MainField from '../components/MainField.vue'
import { getAddressHint } from '../api/dadata'
export default {
  components: { 
    MainField
  },

  props: {
    modelValue: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String,
      default: null
    },
    returnMasked: {
      type: Boolean,
      default: false
    },
    eager: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const focused = ref(false)
    const list = ref([])

    function updateAddressHint (value) {
      if (value.length > 3) {
        const res = getAddressHint(value)
        res.then(data => list.value = data.suggestions)
      }
      if (value.length == 0) {
        list.value = []
      }
    }

    return {
      list,
      focused,
      updateAddressHint,
      onfocused: v => focused.value = v,
      update: v => {
        updateAddressHint(v)
        return emit('update:model-value', v)
      }
    }
  }
}
</script>

<style>
.address-field {
  position: relative;
}
.address-field__wrapper {
  position: absolute;
  top: calc(100% - 22px);
  z-index: 1;
  width: 100%;
  background: #f5f5f5;
  padding-top: 10px;
  padding-bottom: 10px;
  max-height: 122px;
}
.address-field__list {
  padding-left: 16px;
  padding-right: 16px;
  max-height: 102;
  overflow-y: auto;
}
.address-field__item {
  padding: 5px 0;
  cursor: pointer;
}
</style>
