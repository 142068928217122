<template>
  <td>
    <slot>
      <p v-if="value">
        {{ value }}
      </p>
      <p
        v-else
        class="text-gray-500"
      >
        {{ $values.labels.not_defined }}
      </p>
    </slot>
  </td>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: null
    }
  },
  setup() {
    
  }
}
</script>
