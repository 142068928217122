export default {
  family_statuses: [
    'Вдовец (вдова)',
    'Никогда не состоял(а) в браке',
    'Разведен(а) официально (развод зарегистрирован)',
    'Разошелся(лась)',
    'Состоит в зарегистрированном браке',
    'Состоит в незарегистрированном браке'
  ],

  boolean_labels: [
    'Да',
    'Нет'
  ],

  vacancy_source: [
    'hh.ru',
    'Авито',
    'Сайт компании',
    'Иные сайты по поиску работы',
    'Реклама в интернете',
    'Поисковые системы (Yandex, Google и пр.)',
    'Социальные сети (ВКонтакте, Telegram и пр.)',
    'От знакомых / родственников',
    'Листовки / объявления',
    'Реклама в журнале / газете',
    'Другое'
  ],

  military_position: [
    'Рядовой',
    'Матрос',
    'Ефрейтор',
    'Старший матрос',
    'Младший сержант',
    'Старшина 2 статьи',
    'Сержант',
    'Старшина 1 статьи',
    'Старший сержант',
    'Главный старшина',
    'Старшина',
    'Главный корабельный старшина',
    'Прапорщик',
    'Мичман',
    'Старший прапорщик',
    'Старший мичман',
    'Младший лейтенант',
    'Лейтенант',
    'Старший лейтенант',
    'Капитан',
    'Капитан-лейтенант',
    'Майор',
    'Капитан 3 ранга',
    'Подполковник',
    'Капитан 2 ранга',
    'Полковник',
    'Капитан 1 ранга',
    'Генерал-майор',
    'Контр-адмирал',
    'Генерал-лейтенант',
    'Вице-адмирал',
    'Генерал-полковник',
    'Адмирал',
    'Генерал армии',
    'Адмирал флота',
    'Маршал Российской Федерации'
  ],

  member_type: [
    'Бабушка',
    'Брат',
    'Внук',
    'Внучка',
    'Дедушка',
    'Дочь',
    'Жена',
    'Зять',
    'Мать',
    'Мачеха',
    'Муж',
    'Невестка (сноха)',
    'Отец',
    'Отчим',
    'Падчерица',
    'Пасынок',
    'Свекор',
    'Свекровь',
    'Сестра',
    'Сын',
    'Тесть',
    'Теща',
    'Другая степень родства'
  ],

  person_gender: [
    'Мужской',
    'Женский'
  ],

  person_citizenship: [
    'Россия',
    'Не резидент РФ'
  ],

  regions: [
    {id:'79',title:'Адыгея республика'},
    {id:'01',title:'Алтай республика'},
    {id:'01',title:'Алтайский край'},
    {id:'10',title:'Амурская область'},
    {id:'11',title:'Архангельская область'},
    {id:'12',title:'Астраханская область'},
    {id:'80',title:'Башкортостан республика'},
    {id:'14',title:'Белгородская область'},
    {id:'15',title:'Брянская область'},
    {id:'81',title:'Бурятия республика'},
    {id:'17',title:'Владимирская область'},
    {id:'18',title:'Волгоградская область'},
    {id:'19',title:'Вологодская область'},
    {id:'20',title:'Воронежская область'},
    {id:'82',title:'Дагестан республика'},
    {id:'21',title:'Донецкая Народная Республика'},
    {id:'99',title:'Еврейская автономная область'},
    {id:'76',title:'Забайкальский край'},
    {id:'23',title:'Запорожская область'},
    {id:'24',title:'Ивановская область'},
    {id:'26',title:'Ингушетия республика'},
    {id:'25',title:'Иркутская область'},
    {id:'83',title:'Кабардино-Балкарская республика'},
    {id:'27',title:'Калининградская область'},
    {id:'85',title:'Калмыкия республика'},
    {id:'29',title:'Калужская область'},
    {id:'30',title:'Камчатский край'},
    {id:'91',title:'Карачаево-Черкесская республика'},
    {id:'86',title:'Карелия республика'},
    {id:'32',title:'Кемеровская область'},
    {id:'33',title:'Кировская область'},
    {id:'87',title:'Коми республика'},
    {id:'34',title:'Костромская область'},
    {id:'03',title:'Краснодарский край'},
    {id:'04',title:'Красноярский край'},
    {id:'35',title:'Крым республика (исключая Севастополь)'},
    {id:'37',title:'Курганская область'},
    {id:'38',title:'Курская область'},
    {id:'40',title:'Ленинградская область'},
    {id:'42',title:'Липецкая область'},
    {id:'43',title:'Луганская Народная Республика'},
    {id:'44',title:'Магаданская область'},
    {id:'88',title:'Марий Эл республика'},
    {id:'89',title:'Мордовия республика'},
    {id:'45',title:'Москва город федерального значения'},
    {id:'45',title:'Московская область'},
    {id:'47',title:'Мурманская область'},
    {id:'22',title:'Нижегородская область'},
    {id:'49',title:'Новгородская область'},
    {id:'50',title:'Новосибирская область'},
    {id:'52',title:'Омская область'},
    {id:'53',title:'Оренбургская область'},
    {id:'54',title:'Орловская область'},
    {id:'56',title:'Пензенская область'},
    {id:'57',title:'Пермский край'},
    {id:'05',title:'Приморский край'},
    {id:'58',title:'Псковская область'},
    {id:'60',title:'Ростовская область'},
    {id:'61',title:'Рязанская область'},
    {id:'36',title:'Самарская область'},
    {id:'40',title:'Санкт-Петербург'},
    {id:'63',title:'Саратовская область'},
    {id:'98',title:'Саха (Якутия) республика'},
    {id:'64',title:'Сахалинская область'},
    {id:'65',title:'Свердловская область'},
    {id:'67',title:'Севастополь город федерального значения'},
    {id:'90',title:'Северная Осетия – Алания республика'},
    {id:'66',title:'Смоленская область'},
    {id:'07',title:'Ставропольский край'},
    {id:'68',title:'Тамбовская область'},
    {id:'92',title:'Татарстан республика'},
    {id:'28',title:'Тверская область'},
    {id:'69',title:'Томская область'},
    {id:'70',title:'Тульская область'},
    {id:'93',title:'Тыва республика'},
    {id:'71',title:'Тюменская область'},
    {id:'94',title:'Удмуртская республика'},
    {id:'73',title:'Ульяновская область'},
    {id:'08',title:'Хабаровский край'},
    {id:'95',title:'Хакасия республика'},
    {id:'71',title:'Ханты-Мансийский автономный округ'},
    {id:'74',title:'Херсонская область'},
    {id:'75',title:'Челябинская область'},
    {id:'96',title:'Чеченская республика'},
    {id:'97',title:'Чувашия республика'},
    {id:'77',title:'Чукотский автономный округ'},
    {id:'78',title:'Ярославская область'},
  ],
  languages: [
    'Не владею',
    'Английский',
    'Азербайджанский',
    'Армянский',
    'Грузинский',
    'Испанский',
    'Итальянский',
    'Казахский',
    'Китайский',
    'Корейский',
    'Монгольский',
    'Немецкий',
    'Польский',
    'Французский',
    'Финский',
    'Шведский',
    'Японский',
  ],
  languages_statuses: [
    'Читает и переводит со словарем',
    'Читает и может объясняться',
    'Владеет свободно'
  ]
}
