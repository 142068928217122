<template>
  <div class="h-full">
    <user-table :users="users" />
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useManagementStore } from 'stores/management'

import UserTable from 'components/user/UserTable.vue'

export default {
  components: { UserTable },
  setup() {
    const management = useManagementStore()

    const users = ref(null)

    onMounted(() => {
      management.fetchUsers().then(({ users: x }) => users.value = x)
    })

    return {
      users
    }
  }
}
</script>

