import { format, parseISO } from 'date-fns'
import { injectBooleanFromLabel, injectLabelFromBoolean, toPartialName, injectLabelFromObject, injectObjectFromLabel } from '../utils'

export const getMaterialJobName = material => material.data.job_name

export const getMaterialPartialPersonName = material => toPartialName(
  material.data.person_name,
  material.data.person_surname,
  material.data.person_patronymic
)

export const getMaterialPersonPhone = material => material.data.person_phone

export const getMaterialCreatedAt = material => material.created_at && format(parseISO(material.created_at), 'Y-MM-dd HH:mm')
export const getMaterialCompletedAt = material => material.data.completed_at && format(parseISO(material.data.completed_at), 'Y-MM-dd HH:mm')

export const fromMaterial = x => {
  const { 
    military_status,
    other_driver_car,
    other_disability,
    other_health_restriction,
    other_work_status,
    other_owner_status,
    other_nepotism_status,
    other_criminal_now,
    other_criminal_past,
    person_address_plan_region
  } = x['data'] || {}

  const r = {
    ...x,
    data: {
      ...x['data'],
      ...injectLabelFromBoolean('military_status', military_status),
      ...injectLabelFromBoolean('other_driver_car', other_driver_car),
      ...injectLabelFromBoolean('other_disability', other_disability),
      ...injectLabelFromBoolean('other_health_restriction', other_health_restriction),
      ...injectLabelFromBoolean('other_work_status', other_work_status),
      ...injectLabelFromBoolean('other_owner_status', other_owner_status),
      ...injectLabelFromBoolean('other_nepotism_status', other_nepotism_status),
      ...injectLabelFromBoolean('other_criminal_now', other_criminal_now),
      ...injectLabelFromBoolean('other_criminal_past', other_criminal_past),
      ...injectObjectFromLabel('person_address_plan_region', person_address_plan_region, 'regions')
    }
  }

  return r
}

export const toMaterial = x => {
  const { 
    military_status,
    other_driver_car,
    other_disability,
    other_health_restriction,
    other_work_status,
    other_owner_status,
    other_nepotism_status,
    other_criminal_now,
    other_criminal_past,
    person_address_plan_region
  } = x['data'] || {} 

  const r = {
    ...x,
    data: {
      ...x['data'],
      ...injectBooleanFromLabel('military_status', military_status),
      ...injectBooleanFromLabel('other_driver_car', other_driver_car),
      ...injectBooleanFromLabel('other_disability', other_disability),
      ...injectBooleanFromLabel('other_health_restriction', other_health_restriction),
      ...injectBooleanFromLabel('other_work_status', other_work_status),
      ...injectBooleanFromLabel('other_owner_status', other_owner_status),
      ...injectBooleanFromLabel('other_nepotism_status', other_nepotism_status),
      ...injectBooleanFromLabel('other_criminal_now', other_criminal_now),
      ...injectBooleanFromLabel('other_criminal_past', other_criminal_past),
      ...injectLabelFromObject('person_address_plan_region', person_address_plan_region, )
    }
  }

  return r
}

export const getFields = () => {

}
