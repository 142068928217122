<template>
  <div>
    <v-table
      v-if="users"
      fixed-header
      height="100%"
    >
      <!-- Header -->
      <thead>
        <tr>
          <th
            v-for="column in columns"
            :key="column.id"
          >
            {{ column.label }}
          </th>
        </tr>
      </thead>

      <!-- Body -->
      <tbody>
        <tr
          v-for="user in users"
          :key="user.id"
          @click="itemClick(user)"
        >
          <td>{{ getUserPhone(user) }}</td>
          <td>{{ getUserCreatedAt(user) }}</td>
        </tr>
      </tbody>
    </v-table>

    <loading-overlay v-else />
  </div>
</template>

<script>
import { key, useValues } from 'utils'

import { getUserPhone, getUserCreatedAt } from '../../models/user'

import LoadingOverlay from 'elements/LoadingOverlay'

export default {
  components: { LoadingOverlay },
  props: {
    users: {
      type: Array,
      default: () => null
    }
  },
  emits: ['item-click'],
  setup(_, { emit }) {
    const $values = useValues()

    return {
      itemClick: item => emit('item-click', item),

      getUserPhone,
      getUserCreatedAt,

      columns: [
        {
          id: key(),
          label: $values.labels.phone
        },
        {
          id: key(),
          label: $values.labels.register_at
        }
      ]
    }
  }
}
</script>
