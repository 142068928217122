<template>
  <main-layout :title="$values.labels.material">
    <!-- Steps -->
    <step-line
      class="mb-2"
      :model-value="step"
      :from="stepFrom"
      :to="stepTo"
      :labels="stepLabels"
    />

    <!-- About required sign -->
    <p class="mb-5 text-right">
      <span class="text-red font-bold">*&nbsp;</span>
      <span class="text-gray-500">{{ $values.labels.about_required_sign }}</span>
    </p>

    <!-- Step 1 -->
    <material-page-step-one
      v-if="step === 1"
      :model-value="materialData"
      :errors="errors"
      :required-fields="requiredFields"
      :addressPlanError="addressPlanError"
      :addressFactError="addressFactError"
      @update-field="updateField"
      @set-address-plan-advance="() => addressPlanAdvance = true"
      @set-address-fact-advance="() => addressFactAdvance = true"
    />

    <!-- Step 2 -->
    <material-page-step-two
      v-if="step === 2"
      :model-value="materialData"
      :errors="errors"
      :disableTin="processFields.inn.checked || loading"
      :disablePassport="processFields.passport.checked || loading"
      :required-fields="requiredFields"
      @update-field="updateField"
    />

    <!-- Step 3 -->
    <material-page-step-three
      v-if="step === 3"
      :model-value="materialData"
      :errors="errors"
      :required-fields="requiredFields"
      @update-field="updateField"
      @append-nested-item="appendNestedItem"
      @remove-nested-item="removeNestedItem"
      @update-nested-field="updateNestedField"
    />

    <!-- Step 4 -->
    <material-page-step-four
      v-if="step === 4"
      :model-value="materialData"
      :errors="errors"
      :required-fields="requiredFields"
      @update-field="updateField"
      @append-nested-item="appendNestedItem"
      @remove-nested-item="removeNestedItem"
      @update-nested-field="updateNestedField"
    />

    <!-- Step 5 -->
    <material-page-step-five
      v-if="step === 5"
      :model-value="materialData"
      :errors="errors"
      :required-fields="requiredFields"
      @update-field="updateField"
    />

    <!-- Step 6 -->
    <material-page-step-six
      v-if="step === 6"
      :model-value="materialData"
      :errors="errors"
      :required-fields="requiredFields"
      @update-field="updateField"
      @append-nested-item="appendNestedItem"
      @remove-nested-item="removeNestedItem"
      @update-nested-field="updateNestedField"
    />

    <!-- Step 7 -->
    <material-page-step-seven
      v-if="step === 7"
      :model-value="materialData"
      :errors="errors"
      :required-fields="requiredFields"
      @update-field="updateField"
    />
    <!-- Controls -->
    <div class="flex justify-between mt-3">
      <!-- Prev -->
      <div class="w-1/3">
        <v-btn
          v-if="step !== stepFrom"
          class="w-full"
          rounded="pill"
          @click="prev"
        >
          {{ $values.labels.back }}
        </v-btn>
      </div>
      <!-- Loading -->
      <div v-if="loading" class="w-2/3 flex">
        <v-btn
          class="w-5/6"
          rounded="pill"
          color="primary"
          disabled
          style="margin-left: auto"
        >
          {{ $values.labels.checkingFormData }}
          <Loading />
        </v-btn>
      </div>
      <!-- Next or complete -->
      <div v-if="needCheckField && step == 2 && (!loading && !(processFields.inn.checked && processFields.passport.checked))" class="w-2/3 flex">
        <!-- Check -->
        <v-btn
          v-if="step == 2 && !(processFields.inn.checked && processFields.passport.checked) && !loading"
          class="w-3/4"
          rounded="pill"
          color="primary"
          @click="checkField"
          style="margin-left: auto"
        >
          {{ checkButtonLabel }}
        </v-btn>
      </div>
      <div v-if="!needCheckField || !loading && (step != 2 || (processFields.inn.checked && processFields.passport.checked))" class="w-2/3 flex">
        <!-- Next -->
        <v-btn
          v-if="!needCheckField || step != 2 ? step !== stepTo : (processFields.inn.checked && processFields.passport.checked)"
          class="w-3/4"
          rounded="pill"
          color="primary"
          @click="nextHandler"
          style="margin-left: auto"
        >
          {{ step != 2 || !needCheckField ? $values.labels.continue : (processFields.inn.checked && processFields.passport.checked) ? $values.labels.continue : $values.labels.forceContinue }}
        </v-btn>

        <!-- Complete -->
        <v-btn
          v-if="step === stepTo && !loading"
          class="w-3/4"
          rounded="pill"
          color="primary"
          :disabled="!completable || loading"
          style="margin-left: auto"
          @click="complete"
        >
          {{ $values.labels.complete }}
        </v-btn>
      </div>
    </div>
    <Popup @close="croinformError = ''" v-if="croinformError"><div v-html="croinformError"></div></Popup>
  </main-layout>
</template>

<script>
import { computed, onMounted, ref, watch, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { difference, key, union, useValues } from '../utils'

import { checkInn, checkPassport, checkInnResult, checkPassportResult } from '../api/croinform'

import { useMaterialStore } from '../stores/materials'
import { useAuthStore } from '../stores/auth'

import { fromMaterial, toMaterial } from 'models/material'
import { getAddressHint } from '../api/dadata'

import MainLayout from '../components/MainLayout.vue'
import StepLine from '../components/StepLine.vue'
import MaterialPageStepOne from './MaterialPageStepOne.vue'
import MaterialPageStepTwo from './MaterialPageStepTwo.vue'
import MaterialPageStepThree from './MaterialPageStepThree.vue'
import MaterialPageStepFour from './MaterialPageStepFour.vue'
import MaterialPageStepFive from './MaterialPageStepFive.vue'
import MaterialPageStepSix from './MaterialPageStepSix.vue'
import MaterialPageStepSeven from './MaterialPageStepSeven.vue'
import Loading from '../components/Loading.vue'
import Popup from '../components/Popup.vue'


export default {
  components: { 
    MainLayout, 
    StepLine,
    MaterialPageStepOne, 
    MaterialPageStepTwo, 
    MaterialPageStepThree, 
    MaterialPageStepFour,
    MaterialPageStepFive,
    MaterialPageStepSix,
    MaterialPageStepSeven,
    Popup,
    Loading
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const $values = useValues()

    const { applyMaterial, completeMaterial, fetchCurrentMaterial, fetchMaterialsRequiredFields } = useMaterialStore()
    const auth = useAuthStore()

    const value = ref({})
    const material = computed(() => value.value)
    const materialData = computed(() => value.value['data'] || {})

    const loading = ref(false)

    const croinformStatuses = ref({
      'NOT_FOUND': 0,
      'FOUND': 1,
      'ERROR': 3
    })

    const croinformError = ref('')

    const processFields = ref({
      inn: {
        correct: false,
        checked: false,
        tryCount: 0,
      },
      passport: {
        correct: false,
        checked: false,
        tryCount: 0,
      },
      expertise: {
        correct: false,
        checked: false,
        tryCount: 0,
      },
      total_checked: false
    })

    const checkButtonLabel = computed(() => {
      if (!processFields.value.inn.checked) {
        if (processFields.value.inn.tryCount < 2) {
          return $values.labels.check_inn
        }
      }
      if (!processFields.value.passport.checked) {
        if (processFields.value.passport.tryCount < 2) {
          return $values.labels.check_passport
        }
      }
    })

    const timeouts = ref({
      inn: 7, //sec
      passport: 7,
      expertise: 60
    })
    const tryCount = ref(3)

    const errors = ref({})
    const requiredFields = ref({})

    const step = ref(1)
    const stepFrom = 1
    const stepTo = 7
    const stepLabels = {
      1: $values.labels.about_person,
      2: $values.labels.about_document,
      3: $values.labels.about_family,
      4: `${$values.labels.about_background} / ${$values.labels.about_recommendation}`,
      5: $values.labels.about_military,
      6: $values.labels.about_other,
      7: $values.labels.about_consent
    }

    const stepFields = {
      1: [
        'job_name','person_name', 'person_surname', 'person_patronymic', 'person_place_of_birth', 'person_gender', 'person_at', 'person_citizenship',
        'person_address_plan',
        'person_address_plan_region',
        'person_address_plan_region_name',
        'person_address_plan_federal_district',
        'person_address_plan_country',
        'person_address_plan_city',
        'person_address_plan_settlement',
        'person_address_plan_street',
        'person_address_plan_house',
        'person_address_plan_block',
        'person_address_plan_flat',
        'person_address_plan_postal_code',
        'person_address_plan_clear_region',
        'person_address_plan_clear_region_name',
        'person_address_plan_clear_federal_district',
        'person_address_plan_clear_country',
        'person_address_plan_clear_city',
        'person_address_plan_clear_settlement',
        'person_address_plan_clear_street',
        'person_address_plan_clear_house',
        'person_address_plan_clear_block',
        'person_address_plan_clear_flat',
        'person_address_plan_clear_postal_code',
        'person_same_address',
        'person_address_fact',
        'person_address_fact_region_name',
        'person_address_fact_federal_district',
        'person_address_fact_country',
        'person_address_fact_city',
        'person_address_fact_settlement',
        'person_address_fact_street',
        'person_address_fact_house',
        'person_address_fact_block',
        'person_address_fact_flat',
        'person_address_fact_postal_code',
        'person_phone', 'person_phone_additional'],
      2: ['passport_seria', 'passport_number', 'passport_code', 'passport_at', 'passport_by', 'passport_subdivision_code', 'document_sns', 'document_tin', 'inn_correct', 'passport_correct'],
      3: ['family_status', 'family_members'],
      4: ['backgrounds', 'languages', 'recommendations'],
      5: ['military_status', 'military_position', 'military_location', 'military_resistance'],
      6: ['other_referer',
        'other_driver_license',
        'other_driver_category',
        'other_driver_seria',
        'other_driver_number',
        'other_driver_date',
        'other_driver_gos',
        'other_driver_car',
        'other_disability',
        'other_health_restriction',
        'other_work_status',
        'other_work_values',
        'other_owner_status',
        'other_owner_values',
        'other_nepotism_status',
        'other_nepotism_values'],
      7: ['consent_gov_job', 'consent_trusty', 'expertise_correct']
    }

    const addressPlanError = ref(false)
    const addressFactError = ref(false)
    const addressPlanAdvance = ref(false)
    const addressFactAdvance = ref(false)
    

    const completable = computed(() => value.value['data']?.['consent_gov_job'] && value.value['data']?.['consent_trusty'])
    const needCheckField = computed(() => value.value['data']?.['person_citizenship'] == 'Россия')

    watch(() => route.query.step, x => step.value = +x, { immediate: true })
    watch(materialData, () => {
    })

    const patch = changes => {
      value.value = {
        ...value.value,
        data: {
          ...value.value['data'],
          ...changes
        }
      }
    }

    const appendNestedItem = ([f, x]) => patch({ [f]: union(value.value['data'][f] || [], [x], (a, b) => a.id === b.id) })
    const removeNestedItem = ([f, x]) => patch({ [f]: difference(value.value['data'][f] || [], [x], (a, b) => a.id === b.id) })
    const clearNestedItems = ([f]) => patch({ [f]: [] })
    const updateNestedField = ([k, v, f, x]) => patch({ [f]: value.value['data'][f].map(y => y.id === x.id ? { ...x, [k]: v } : y)  })

    const postprocessFieldUpdation = ([k, v]) => {
      k === 'other_work_status' && v === $values.enums.boolean_labels[0] && appendNestedItem(['other_work_values', { id: key() }])
      k === 'other_work_status' && v === $values.enums.boolean_labels[1] && clearNestedItems(['other_work_values'])

      k === 'other_owner_status' && v === $values.enums.boolean_labels[0] && appendNestedItem(['other_owner_values', { id: key() }])
      k === 'other_owner_status' && v === $values.enums.boolean_labels[1] && clearNestedItems(['other_owner_values'])

      k === 'other_nepotism_status' && v === $values.enums.boolean_labels[0] && appendNestedItem(['other_nepotism_values', { id: key() }])
      k === 'other_nepotism_status' && v === $values.enums.boolean_labels[1] && clearNestedItems(['other_nepotism_values'])
    }

    onMounted(async () => {
      await fetchCurrentMaterial().then(material => value.value = fromMaterial(material))
      await fetchMaterialsRequiredFields().then(({ fields }) => requiredFields.value = fields.reduce((r, x) => (r[x] = true) && r, {}))
    })

    function updateField ([k, v]) {
      return (patch({ [k]: v }) || true) && postprocessFieldUpdation([k, v])
    }

    function setAddressFields (data, field, isClear = false) {
      const fields = [
        {name: field + '_region_name', fieldname: 'region_with_type'},
        {name: field + '_region_shortname', fieldname: 'region'},
        {name: field + '_federal_district', fieldname: 'federal_district'},
        {name: field + '_country', fieldname: 'country'},
        {name: field + '_city', fieldname: 'city', prefix: 'city_type_full', noDot: true},
        {name: field + '_city_shortname', fieldname: 'city'},
        {name: field + '_settlement', fieldname: 'settlement', prefix: 'settlement_type_full', noDot: true},
        {name: field + '_street', fieldname: 'street', prefix: 'street_type_full', noDot: true},
        {name: field + '_house', fieldname: 'house', prefix: 'house_type'},
        {name: field + '_block', fieldname: 'block', prefix: 'block_type'},
        {name: field + '_flat', fieldname: 'flat', prefix: 'flat_type'},
        {name: field + '_postal_code', fieldname: 'postal_code'},
      ]
      if (data && data.length > 0) {
        const hintData = data[0].data
        fields.forEach(item => {
          let fieldData = hintData[item.fieldname]
          if (isClear && fieldData) {
            fieldData = fieldData.replace(/[^а-яА-Я\d\s№-]/g, '')
          }
          let prefix = ''
          if (!isClear && item.prefix && hintData[item.prefix]) {
            prefix = hintData[item.prefix] + (item.noDot ? ' ' : '. ')
          }
          updateField([item.name, (fieldData ? prefix + fieldData : fieldData) || ''])
        })
      } else {
        fields.forEach(item => {
          updateField([item.name, ''])
        })
      }
    }

    function processAddress (address) {
      let out = ''
      out += value.value.data[address + '_country']
      if (value.value.data[address + '_region_name'] && (value.value.data[address + '_region_shortname'] != value.value.data[address + '_city_shortname'])) {
        out += ', '
        out += value.value.data[address + '_region_name']
      }
      if (value.value.data[address + '_city']) {
        let city = value.value.data[address + '_city']
        city = city.replace('город', '')
        city = city.replace(/г[\s \.\,]/, '')
        city = city.replace(/[№,.]/i, '')
        city = city.replace(/[^а-яА-Яa-zA-Z\d\s№-]/g, '')
        updateField([address + '_city', city])
        out += ', '
        out += city
      }
      if (value.value.data[address + '_settlement']) {
        out += ', '
        out += value.value.data[address + '_settlement']
      }
      if (value.value.data[address + '_street']) {
        let street = value.value.data[address + '_street']
        street = street.replace('улица', '')
        street = street.replace(/ул[\s \.\,]/i, '')
        street = street.replace(/[№,.]/i, '')
        street = street.replace(/[^а-яА-Яa-zA-Z\d\s№-]/g, '')
        updateField([address + '_street', street])
        out += ', '
        out += street
      }
      if (value.value.data[address + '_house']) {
        let house = value.value.data[address + '_house']
        house = house.replace('дом', '')
        house = house.replace(/д[\s \.\,]/i, '')
        house = house.replace(/[№,.]/i, '')
        house = house.replace(/[^а-яА-Яa-zA-Z\d\s№-]/g, '')
        updateField([address + '_house', house])
        out += ', '
        out += house
      }
      if (value.value.data[address + '_block']) {
        let block = value.value.data[address + '_block']
        block = block.replace(/(\B[а-яА-Я]+)/gi, '')
        block = block.replace(/[№,.\s]/i, '')
        block = block.replace(/[^а-яА-Яa-zA-Z\d\s№-]/g, '')
        updateField([address + '_block', block])
        out += ', '
        out += block
      }
      if (value.value.data[address + '_flat']) {
        let flat = value.value.data[address + '_flat']
        flat = flat.replace('квартира', '')
        flat = flat.replace('кв', '')
        flat = flat.replace(/[№,.]/i, '')
        flat = flat.replace(/[^а-яА-Яa-zA-Z\d\s№-]/g, '')
        updateField([address + '_flat', flat])
        out += ', '
        out += flat
      }
      return out
    }

    function setStandartAddress () {
      if (addressPlanAdvance.value || addressFactAdvance.value) {
        if (addressPlanAdvance.value) {
          updateField(['person_address_plan', processAddress('person_address_plan')])
          updateField(['person_address_plan_clear_city', value.value.data['person_address_plan_city']])
          updateField(['person_address_plan_clear_country', value.value.data['person_address_plan_country']])
          updateField(['person_address_plan_clear_flat', value.value.data['person_address_plan_flat']])
          updateField(['person_address_plan_clear_house', value.value.data['person_address_plan_house']])
          updateField(['person_address_plan_clear_street', value.value.data['person_address_plan_street']])
          updateField(['person_address_plan_clear_block', value.value.data['person_address_plan_block']])
          updateField(['person_address_plan_clear_settlement', value.value.data['person_address_plan_city']])
        }

        updateField(['person_address_fact', processAddress('person_address_fact')])
        return Promise.resolve()
      } else {
        const prom = []
        prom.push(getAddressHint(value.value.data.person_address_plan))
        prom.push(getAddressHint(value.value.data.person_address_fact))
        return Promise.all(prom).then((data) => {
          let error = false
          if (!data[0] || data[0].suggestions.length == 0) {
            addressPlanError.value = true
            error = true
          }
          if (!data[1] || data[1].suggestions.length == 0) {
            addressFactError.value = true
            error = true
          }
          if (error) {
            return Promise.reject(new Error('Address not found in dadata'))
          }
          setAddressFields(data[0].suggestions,'person_address_plan')
          setAddressFields(data[0].suggestions,'person_address_plan_clear', true)
          setAddressFields(data[1].suggestions,'person_address_fact')
        })
      }
    }

    function next () {
      return step.value !== stepTo && applyMaterial({
      material: toMaterial(value.value).data,
      is: stepFields[step.value]
    })
      .then(material => value.value = fromMaterial(material))
      .then(() => router.push({ name: 'materials', query: { step: step.value + 1 } }))
      .catch(({ messages: x }) => errors.value = x)
    }
    
    function standardizationNext () {
      if (value.value.data.person_same_address) {
        if (addressPlanAdvance.value) {
          updateField(['person_address_fact_city', value.value.data['person_address_plan_city']])
          updateField(['person_address_fact_country', value.value.data['person_address_plan_country']])
          updateField(['person_address_fact_flat', value.value.data['person_address_plan_flat']])
          updateField(['person_address_fact_house', value.value.data['person_address_plan_house']])
          updateField(['person_address_fact_street', value.value.data['person_address_plan_street']])
          updateField(['person_address_fact_block', value.value.data['person_address_plan_block']])
        } else {
          updateField(['person_address_fact', value.value.data.person_address_plan])
        }
      }
      nextTick(() => {
        setStandartAddress()
        .then(() => {
          return step.value !== stepTo && applyMaterial({
          material: toMaterial(value.value).data,
          is: stepFields[step.value]
        })})
        .then(material => value.value = fromMaterial(material))
        .then(() => router.push({ name: 'materials', query: { step: step.value + 1 } }))
        .catch(({ messages: x }) => {return errors.value = x})
      })
    }

    function sendCheckRequest (field, data, materialField, checkFunction, checkResultFunction, timeout, additionData = null) {
      material.value.data[materialField] = false
      if (data) {
        loading.value = true
        field.tryCount += 1
        checkFunction(data).then(
          (r) => {
            if (r.Error) {
              croinformError.value = $values.labels.checkError
              loading.value = false

              if (field.tryCount >= 2) {
                field.checked = true
              }
              return
            }
            if (r.RequestNumber) {
              let i = 0
              let paused = false
              const interval = setInterval(() => {
                if (!paused) {
                  i += 1
                  paused = true
                  let params = null
                  if (additionData) {
                    params = {
                      requestNumber: r.RequestNumber
                    }
                    params = Object.assign(params, additionData)
                  } else {
                    params = r.RequestNumber
                  }
                  checkResultFunction(params).then(
                    (r) => {
                      if (Number(r.status) == Number(croinformStatuses.value.FOUND)) {
                        field.correct = true
                        field.checked = true
                        material.value.data[materialField] = true
                      }
                      if (Number(r.status) == Number(croinformStatuses.value.NOT_FOUND)) {
                        croinformError.value = $values.labels.checkError
                      }
                      if (Number(r.status) != Number(croinformStatuses.value.ERROR)) {
                        loading.value = false
                        clearInterval(interval)
                      }
                    }
                  ).catch(() => {
                    loading.value = false
                  })
                  .finally(() => {
                    paused = false
                  })
                  if (field.tryCount >= 2) {
                    field.checked = true
                  }
                  if (i >= tryCount.value) {
                    loading.value = false
                    clearInterval(interval)
                  }
                }
              }, timeout * 1000)
            }
          }
        ).catch(() => {
          loading.value = false
        })
        return
      }
    }

    return {
      material,
      materialData,
      errors,
      requiredFields,
      processFields,
      croinformStatuses,
      loading,
      croinformError,
      checkButtonLabel,
      timeouts,
      tryCount,

      step,
      stepFrom,
      stepTo,
      stepLabels,

      completable,
      needCheckField,

      addressPlanError,
      addressFactError,
      addressPlanAdvance,
      addressFactAdvance,

      updateField,
      appendNestedItem,
      removeNestedItem,
      updateNestedField,
      sendCheckRequest,
      setStandartAddress,
      standardizationNext,
      next,

      nextHandler: () => {
        step.value == 1 ? standardizationNext() : next()
      },

      prev: () => step.value !== stepFrom && router.push({ name: 'materials', query: { step: step.value - 1 } }),

      checkField: () => {
        if (route.query.noCheck) {
          processFields.value.inn.checked = true
          processFields.value.passport.checked = true
        }
        if (!processFields.value.inn.checked) {
          sendCheckRequest(
            processFields.value.inn,
            {inn: material.value.data.document_tin},
            'inn_correct',
            checkInn,
            checkInnResult,
            timeouts.value.inn
          )
          return
        }
        
        if (!processFields.value.passport.checked) {
          sendCheckRequest(
            processFields.value.passport,
            {passport: material.value.data.passport_seria + material.value.data.passport_number},
            'passport_correct',
            checkPassport,
            checkPassportResult,
            timeouts.value.passport
          )
          return
        }
      },

      complete: () => {
        return step.value === stepTo && applyMaterial({
        material: toMaterial(value.value).data,
        is: stepFields[step.value]
      })
        .then(() => completeMaterial({ material: toMaterial(value.value) }))
        .then(() => auth.logout())
        .then(() => value.value = {})
        .then(() => router.push({ name: 'materials-completed' }))
        .catch(({ messages: x }) => errors.value = x)},

      dd: x => console.log(x)
    }
  }
}
</script>
