<template>
  <div>
    <p>
      Настоящим Я, именуемый(ая) в дальнейшем «Соискатель», как субъект персональных данных, в соответствии с требованиями Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», действуя своей волей и в своем интересе <span class="font-bold">даю свое согласие</span> АО «ГК «ОСНОВА» (далее – Оператор, Работодатель), зарегистрированному по адресу: 142702, Московская обл., город Видное, рабочий поселок Бутово, территория жилой комплекс Бутово-Парк, д. 18, корп. 1, пом. 59 на обработку своих персональных данных, а именно на сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление и уничтожение, как с использованием средств автоматизации, так и без использования таковых, в том числе с передачей по внутренней сети Оператора с соблюдением мер, обеспечивающих их защиту от несанкционированного доступа третьих лиц.
    </p><br>

    <p>
      1. Мои персональные данные для обработки указанными выше способами передаются Оператору с целями:
    </p><br>

    <ul style="list-style: inside">
      <li> рассмотрения моей кандидатуры для трудоустройства; </li>
      <li> направления мне информации, связанной с поиском работы, в том числе: предложений вакансий, приглашений на собеседования, информации о результатах собеседования, запрос рекомендаций. </li>
      <li> включения в кадровый резерв.</li>
    </ul><br>

    <p>2. В целях проверки достоверности предоставленных мною сведений и моей благонадежности, даю свое согласие на предоставление моих персональных данных для обработки указанными выше способами обществам, входящим в Группу компаний «Кронос-Информ»:</p>
    <br>
    <ul style="list-style: inside">
      <li>АО «МБКИ», ИНН 7710606134, ОГРН 1057748903618, адрес места нахождения: 127006, г. Москва, ул. Садовая-Триумфальная, д. 4-10;</li>
      <li>ЗАО «Научно-производственная компания «КРОНОС-ИНФОРМ», ИНН 7710296027, ОГРН 1027739838675, адрес места нахождения: 123056, г. Москва, пер. Электрический, дом 12, пом. II, комн. 6;</li>
      <li>ООО «НПК «Кронос-Информ», ИНН 7713656013, ОГРН 1087746762949, адрес места нахождения: 125130, г. Москва, ул. Приорова, д. 30;</li>
      <li>ООО «МБКИ», ИНН 7713390123, ОГРН 1157746058931, адрес места нахождения: 125130, г. Москва, ул. Приорова, д. 30.</li>
    </ul>
    <br>

    <p>
      3. Для реализации указанных выше целей и в срок по их достижению я предоставляю следующие персональные данные:
    </p><br>

    <ul style="list-style: inside">
      <li> фамилия, имя, отчество, дата рождения, гражданство; </li>
      <li> номер личного мобильного телефона; </li>
      <li> сведения о фактическом месте проживания и адресе регистрации: регион, район, город, населенный пункт, улица, дом, корпус, квартира,); </li>
      <li> сведения о документе, удостоверяющем личность: вид документа, серия, номер, дата выдачи, государственный орган, выдавший документ; </li>
      <li> сведения, содержащиеся в страховом свидетельстве государственного пенсионного страхования (СНИЛС); </li>
      <li> сведения о налоговом статусе: ИНН </li>
      <li> семейное положение, сведения о составе семьи: фамилия, имя, отчество, год рождения, степень родства; </li>
      <li> сведения об образовании и профессиональных сертификатах, в том числе сроки обучения, учреждение, в котором оно получалось, форма обучения, квалификация, степень и специализация, знание иностранных языков; </li>
      <li> сведения о рекомендателях: наименование организации, должность, номер служебного телефона или служебный адрес электронной почты; </li>
      <li> сведения, содержащиеся в документах воинского учета; </li>
      <li> сведения о наличии водительского удостоверения: категория, серия, номер, дата выдачи, государственный орган, выдавший документ; </li>
      <li> сведения о наличии автомобиля; </li>
      <li> cведения о трудоспособности (медицинские ограничения); </li>
      <li> сведения о факте наличия противопоказаний к выполнению работы, которую предполагает должность; </li>
      <li> сведения о трудовой деятельности (работа по совместительству, наименование организации и занимаемая должность); </li>
      <li> сведения о том, являюсь ли я учредителем (участником), генеральным директором, бенефициарным (фактическим) владельцем коммерческих структур (вид деятельности, фактический адрес, дата регистрации и прекращения деятельности); </li>
      <li> иные сведения, которые могут стать доступными в процессе обработки моих персональных данных. </li>
    </ul><br>

    <p>
      Срок действия согласия на обработку персональных данных составляет 5 (пять) лет. 
    </p><br>
    <p>
      Данное согласие на обработку персональных данных может быть отозвано мною в любой момент времени в письменной форме.
    </p>
  </div>
</template>
