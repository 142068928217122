import { toUrlByApiV1, sendPostWithFormData, sendPost, sendGet } from '.'

export const getAddressHint = ( data ) => {
  let input = ''
  if (data) {
    input = data.replace(/(\s[\s]+)/gi, ' ')
  }
  return sendPostWithFormData(toUrlByApiV1('dadata/hint'), { data: input })
}

export const standardizationAddress = ( data ) => 
sendPostWithFormData(toUrlByApiV1('dadata/standardization'), { data })
