<template>
  <div class="popup">
    <div class="popup__background" @click="$emit('close')"></div>
    <div class="popup-window">
      <div class="popup-window__close" @click="$emit('close')">
      </div>
      <div class="popup-window__body">
        <div class="popup-window__content">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
</script>

<style>
.popup {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0,0,0,0.2);
}
.popup__background {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
}
.popup-window {
  position: relative;
  z-index: 2;
  width: 550px;
  max-width: calc(100% - 30px);
  margin: auto;
  min-height: 400px;
  max-height: calc(100% - 30px);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 30px;
  background-color: #fff;
  font-size: 20px;
  display: flex;
}

.popup-window__close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 20px;
  height: 20px;
  z-index: 1;
  cursor: pointer;
}
.popup-window__close::before,
.popup-window__close::after {
  content: '';
  width: 28px;
  height: 2px;
  display: block;
  background-color: #000000;
  position: absolute;
  left: 0;
}
.popup-window__close::before {
  transform: rotate(45deg) translateX(-50%);
  top: 19px;
  left: 6px;
}
.popup-window__close::after {
  transform: rotate(-45deg) translateX(-50%);
  top: -1px;
  left: 6px;
}
.popup-window__body {
  margin: auto;
}
</style>
