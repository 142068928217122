import common from './common'
import labels from './labels'
import enums from './enums'
import defaults from './defaults'
import environment from './environment'

export default {
  common,
  labels,
  enums,
  defaults,
  environment
}
