<template>
  <div class="space-y-2">
    <!-- consent_gov_job -->
    <main-checkbox
      :model-value="modelValue.consent_gov_job"
      name="consent_gov_job"
      :errors="errors"
      :required="isRequiredField('consent_gov_job')"
      class="with-top-label with-top-control"
      @update:model-value="x => updateField('consent_gov_job', x)"
    >
      <template #label>
        <div>
          <p>{{ $values.labels.consent_gov_job_a }}</p><br>
          <p>{{ $values.labels.consent_gov_job_b }}</p>
        </div>
      </template>
    </main-checkbox>

    <!-- consent_trusty -->
    <main-checkbox
      :model-value="modelValue.consent_trusty"
      name="consent_trusty"
      :errors="errors"
      :required="isRequiredField('consent_trusty')"
      class="with-top-label with-top-control"
      @update:model-value="x => updateField('consent_trusty', x)"
    >
      <template #label>
        <p class="font-bold">
          {{ $values.labels.consent_trusty }}
        </p><br>
      </template>
    </main-checkbox>
  </div>
</template>

<script>
import MainCheckbox from '../components/MainCheckbox.vue'

export default {
  components: { 
    MainCheckbox
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    requiredFields: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update-field'],
  setup(props, { emit }) {
    return {
      isRequiredField: k => !!props.requiredFields[k],
      updateField: (k, v) => emit('update-field', [k, v])
    }
  }
}
</script>

