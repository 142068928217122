import { nanoid } from 'nanoid'
import { getCurrentInstance } from 'vue'

import values from '../values'

export const useValues = () => getCurrentInstance().appContext.config.globalProperties.$values

export const range = (from, to) => Array(to + 1 - from).fill(0).map((x, y) => x + y + from)

export const key = () => nanoid()

export const unique = (a, predicate = (a, b) => a === b) => a.reduce((b, x) => [...b, ...b.find(y => predicate(x, y)) ? [] : [x]], [])

export const difference = (a, b, predicate = (a, b) => a === b) => a.filter(x => !b.find(y => predicate(x, y)))

export const symmetricalDifference = (a, b, predicate = (a, b) => a === b) => [ ...a.filter(x => !b.find(y => predicate(x, y))), ...b.filter(x => !a.find(y => predicate(x, y))) ]

export const intersection = (a, b, predicate = (a, b) => a === b) => a.filter(x => b.find(y => predicate(x, y)))

export const union = (a, b, predicate = (a, b) => a === b) => [ ...a.filter(x => !b.find(y => predicate(x, y))), ...b ]

export const injectBooleanFromLabel = (k, v) => 
  v !== undefined ? { [k]: v === values.enums.boolean_labels[0] } : {}

export const injectLabelFromBoolean = (k, v) => 
  v !== undefined ? { [k]: v ? values.enums.boolean_labels[0] : values.enums.boolean_labels[1] } : {}

export const injectLabelFromObject = (k, obj) => {
  if (obj) {
    return { [k]: obj.id }
  }
  return {[k]: null}
}

export const injectObjectFromLabel = (k, label, vals) => {
  let out = null
  if (label) {
    const objects = values.enums[vals]
    if (objects) {
      objects.forEach(item => {
        if (item.id == label) {
          out = item
          return 
        }
      })
    }
  }
  return {[k]: out}
}

export const toPartialName = (name, surname, patronymic) => [
  surname && surname,
  name && name.toUpperCase()[0] + '.',
  patronymic && patronymic.toUpperCase()[0] + '.'
].filter(x => x).join(' ')

export const openBlobAsPdf = data => {
  const file = new Blob([data], { type: 'application/pdf' })
  const url = URL.createObjectURL(file)
  window.open(url)
}
