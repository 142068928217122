<template>
  <main-layout without-footer>
    <!-- Content -->
    <dpr-content
      class="overflow-y-scroll mb-4"
      style="height: 100%"
    />
  </main-layout> 
</template>

<script>
import MainLayout from 'components/MainLayout'
import DprContent from 'components/common/DprContent.vue'

export default {
  components: {
    MainLayout,
    DprContent
  },
  setup() {
  }
}
</script>

