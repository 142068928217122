<template>
  <v-checkbox
    :model-value="modelValue"
    :label="labelResult"
    :type="type"
    :error-messages="errorMessages"
    :readonly="readonly"
    @update:model-value="update"
  >
    <template #label>
      <span
        v-if="required"
        style="color: red; font-weight: bold; margin-right: 0.3rem"
      >*</span>
      <slot name="label">
        <span>{{ labelResult }}</span>
      </slot>
    </template>
  </v-checkbox>
</template>

<script>
import { computed } from 'vue'
import { useValues } from '../utils'

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const $values = useValues()

    const labelResult = computed(() => $values.labels[props.label])

    const errorMessages = computed(() => props.errors[props.name] || [])

    return {
      labelResult,
      errorMessages,

      update: v => emit('update:model-value', v)
    }
  }
}
</script>
