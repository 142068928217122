<template>
  <v-select 
    :model-value="modelValue"
    :label="labelResult"
    :items="items"
    :error-messages="errorMessages"
    :return-object="returnObject"
    @update:model-value="update"
  >
    <template #label>
      <span
        v-if="required"
        style="color: red; font-weight: bold"
      >*&nbsp;</span>
      <span>{{ labelResult }}</span>
    </template>
  </v-select>
</template>

<script>
import { computed } from 'vue'
import { useValues } from '../utils'

export default {
  props: {
    modelValue: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    required: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const $values = useValues()

    const labelResult = computed(() => $values.labels[props.label])
    const errorMessages = computed(() => props.errors[props.name] || [])

    return {
      labelResult,
      errorMessages,

      update: v => emit('update:model-value', v)
    }
  }
}
</script>
