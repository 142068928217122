<template>
  <div style="display: grid; grid-template-columns: 1fr 2fr;">
    <!-- Contact -->
    <div class="space-y-2">
      <p class="text-gray-800">
        <v-icon icon="mdi-phone-outline" />&nbsp;<span>{{ $values.common.phone }}</span>
      </p>
      <p class="text-gray-800">
        <v-icon icon="mdi-email-outline" />&nbsp;<span>{{ $values.common.email }}</span>
      </p>
    </div>

    <!-- About -->
    <div class="space-y-4">
      <p class="text-gray-500 text-right">
        <span>{{ $values.common.disclaimer }}</span>&nbsp;
        <router-link to="/dpr">
          <span class="underline underline-offset-2">
            {{ $values.common.disclaimer_link }}
          </span>
        </router-link>
      </p>
      <p class="text-gray-500 text-right und">
        <span>{{ $values.common.copyright }}</span>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  setup() {
    return {} 
  }
}
</script>
