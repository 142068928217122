import values from '../values'
import environment from '../values/environment'

const apiv1 = `${environment.APP_URL}/api/v1`

export const toUrlByApiV1 = path => `${apiv1}/${path}`

const toFormData = value => {
  const data = new FormData()

  Object.entries(value).forEach(([k, v]) => v !== undefined && data.append(k, v))

  return data
}

const middlewares = []

export const registerMiddleware = f => middlewares.push(f)

const thoughMiddlewares = (req, res) => middlewares.reduce((_, x) => x(req, res), res)

const tokens = {}

export const useAccessToken = t => tokens['access_token'] = t
export const useRefreshToken = t => tokens['refresh_token'] = t

const withCommonHeaders = (x = {}) => ({
  ...x,
  ...tokens.access_token && { 'Authorization': `Bearer ${tokens.access_token}` },
  'Accept-Language': 'ru'
})

const fromResponseError = r => {
  values.environment.APP_ENV === 'local' && console.log(r)

  if (!r.ok) {
    return r.json().then(r => {
      throw ({
        messages: r?.data?.data || {}
      })
    })
  }

  return r.json()
}



export const send = ({ url, method = 'get', headers = {}, body, queries, useCommonHeaders = true }) => fetch([
  url,
  queries && '?' + new URLSearchParams(queries).toString()
].filter(x => x).join(''), {
  method,
  headers: useCommonHeaders ? withCommonHeaders(headers) : headers,
  body
})

export const sendWithMiddlewares = req =>
  send(req)
    .then(res => thoughMiddlewares(req, res))
    .then(res => fromResponseError(res))
    .then(res => res.data)

export const sendGet = (url, queries) =>
  sendWithMiddlewares({ url, queries })
export const sendGetForRaw = (url, queries) =>
  send({ url, queries })
    .then(res => thoughMiddlewares({ url, queries }, res))
    .then(res => res.blob())
export const sendPost = url =>
  sendWithMiddlewares({ url, method: 'post' })
export const sendPostWithFormData = (url, value, headers = null) =>
  sendWithMiddlewares({ url, method: 'post', headers: headers, body: toFormData(value) })
export const sendPostWithJson = (url, value, queries) =>
  sendWithMiddlewares({ url, method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(value), queries })
export const sendPutWithJson = (url, value, queries) =>
  sendWithMiddlewares({ url, method: 'put', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(value), queries })
