import { toUrlByApiV1, sendPostWithFormData, sendPost, sendGet } from '.'

export const requestCode = ({ phone }) => 
  sendPostWithFormData(toUrlByApiV1('auth/user/request-code'), { phone })

export const login = ({ publicToken: public_token, code }) => 
  sendPostWithFormData(toUrlByApiV1('auth/user/login'), { public_token, code })

export const loginAsChief = ({ login, password }) =>
  sendPostWithFormData(toUrlByApiV1('auth/chief/login'), { login, password })

export const logout = () =>
  sendPost(toUrlByApiV1('auth/user/logout'))

export const logoutAsChief = () =>
  sendPost(toUrlByApiV1('auth/chief/logout'))

export const refresh = () =>
  sendPost(toUrlByApiV1('auth/refresh'))

export const getMeAsUser = () =>
  sendGet(toUrlByApiV1('auth/user/me'))

export const getMeAsChief = () =>
  sendGet(toUrlByApiV1('auth/chief/me'))
