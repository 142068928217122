<template>
  <v-text-field
    :model-value="value"
    :label="labelResult"
    :type="type"
    :error-messages="errorMessages"
    :readonly="readonly"
    @update:model-value="update"
  >
    <template #label>
      <span
        v-if="required"
        class="text-red font-bold"
      >*&nbsp;</span>
      <span>{{ labelResult }}</span>
    </template>
  </v-text-field>
</template>

<script>
import { computed } from 'vue'
import { Mask } from 'maska'
import { useValues } from '../utils'

export default {
  props: {
    modelValue: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String,
      default: null
    },
    returnMasked: {
      type: Boolean,
      default: false
    },
    eager: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const $values = useValues()

    const labelResult = computed(() => $values.labels[props.label])

    const errorMessages = computed(() => props.errors[props.name] || [])

    const mask = props.mask && new Mask({ mask: props.mask, eager: props.eager })

    const value = computed(() => {
      return mask ? (props.modelValue && mask.masked(props.modelValue)) : props.modelValue
    })

    return {
      value,
      labelResult,
      errorMessages,

      update: v => {
        return props.returnMasked ? emit('update:model-value', mask.masked(v)) : emit('update:model-value', mask ? mask.unmasked(v) : v)
      }
    }
  }
}
</script>
