<template>
  <div class="space-y-2">
    <p class="text-xl font-bold mb-4">
      {{ $values.labels.about_military }}
    </p>

    <!-- Status -->
    <main-select
      :model-value="modelValue.military_status"
      name="military_status"
      label="military_status"
      :errors="errors"
      :required="isRequiredField('military_status')"
      :items="$values.enums.boolean_labels"
      @update:model-value="x => updateField('military_status', x)"
    />

    <!-- Position -->
    <main-select
      :model-value="modelValue.military_position"
      name="military_position"
      label="military_position"
      :errors="errors"
      :required="isRequiredField('military_position')"
      :disabled="!hasPositiveStatus"
      :items="$values.enums.military_position"
      @update:model-value="x => updateField('military_position', x)"
    />

    <!-- Location -->
    <main-field
      :model-value="modelValue.military_location"
      name="military_location"
      label="military_location"
      :errors="errors"
      :required="isRequiredField('military_location')"
      :disabled="!hasPositiveStatus"
      @update:model-value="x => updateField('military_location', x)"
    />

    <!-- Resistance -->
    <main-field
      :model-value="modelValue.military_resistance"
      name="military_resistance"
      label="military_resistance"
      :errors="errors"
      :required="isRequiredField('military_resistance')"
      :disabled="!hasPositiveStatus"
      @update:model-value="x => updateField('military_resistance', x)"
    />
  </div>
</template>

<script>
import { computed } from 'vue'
import { useValues } from '../utils'

import MainField from '../components/MainField.vue'
import MainSelect from '../components/MainSelect.vue'

export default {
  components: {
    MainField,
    MainSelect
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    requiredFields: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update-field'],
  setup(props, { emit }) {
    const $values = useValues()

    const hasPositiveStatus = computed(() => props.modelValue.military_status === $values.enums.boolean_labels[0])

    return {
      hasPositiveStatus,

      isRequiredField: k => !!props.requiredFields[k],
      updateField: (k, v) => emit('update-field', [k, v])
    }
  }
}
</script>
