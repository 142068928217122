import { parseISO } from 'date-fns'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useAccessToken } from '../api'
import { getMeAsChief, getMeAsUser, login, loginAsChief, logout, logoutAsChief, refresh, requestCode } from '../api/auth'
import values from '../values'

export const useAuthStore = defineStore('auth', () => {
  const publicToken = ref(localStorage.getItem('public_token'))
  const accessToken = ref(null)
  const publicTokenAt = ref(Date.parse(localStorage.getItem('public_token_at')))
  const me = ref({})

  // Debug
  const code = ref(null)

  const clearCredentials = () => {
    publicToken.value = null
    publicTokenAt.value = null

    localStorage.removeItem('public_token')
    localStorage.removeItem('public_token_at')
  }

  return {
    accessToken,
    publicToken,
    publicTokenAt,
    me,

    // Debug
    code,

    ...values.environment.APP_ENV === 'local' && { code },

    requestCode: x => requestCode(x).then(({ public_token, code: x, created_at }) => {
      localStorage.setItem('public_token', publicToken.value = public_token)
      localStorage.setItem('public_token_at', publicTokenAt.value = parseISO(created_at))

      values.environment.APP_ENV === 'local' && (code.value = x)
    }),

    sendCode: x => login({ publicToken: publicToken.value, ...x })
      .then(({ access_token }) => {
        useAccessToken(accessToken.value = access_token)
        clearCredentials()
      }),

    logout: () => logout(),
    refresh: () => refresh().then(({ access_token }) => useAccessToken(accessToken.value = access_token)),

    loginAsChief: ({ login, password }) => loginAsChief({ login, password })
      .then(({ access_token }) => useAccessToken(accessToken.value = access_token)),

    logoutAsChief: () => logoutAsChief(),

    fetchMeAsUser: () => getMeAsUser().then(r => me.value = r),
    fetchMeAsChief: () => getMeAsChief().then(r => me.value = r),

    clearCredentials
  }
})
