<template>
  <span class="loading-icon">
    <img src="dist/loading.png">
  </span>
</template>

<style scoped>
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-icon {
  width: 13px;
}
.loading-icon img {
  width: 100%;
  animation: 1s infinite rotate linear;
}
</style>
