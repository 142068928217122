<template>
  <v-btn
    class="w-full mb-6"
    @click="click"
  >
    {{ $values.labels.add }}
  </v-btn>
</template>

<script>
export default {
  emits: ['click'],
  setup(_, { emit }) {
    return {
      click: e => emit('click', e)
    }
  }
}
</script>
