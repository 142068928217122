import { toUrlByApiV1, sendPostWithFormData, sendPost, sendGet } from '.'

export const checkInn = ( data ) => 
  sendPostWithFormData(toUrlByApiV1('croinform/check-inn'), {inn: data.inn})

export const checkPassport = ( data ) => 
sendPostWithFormData(toUrlByApiV1('croinform/check-passport'), { passport: data.passport })

export const checkInnResult = ( requestNumber ) => 
sendPostWithFormData(toUrlByApiV1('croinform/check-inn-result'), { requestNumber })

export const checkPassportResult = ( requestNumber ) => 
sendPostWithFormData(toUrlByApiV1('croinform/check-passport-result'), { requestNumber })
