<template>
  <main-layout
    :title="$values.labels.material"
    :subtitle="$values.labels.about_auth"
  >
    <!-- Login -->
    <login-form
      v-model="credentials"
      :with-code="hasPublicToken"
      :messages="messages"
      :request-code-at="requestCodeAt"
      @request-code="requestCode"
      @send-code="sendCode"
    />

    <!-- Debug -->
    <v-alert
      v-if="shouldDisplayCode"
      class="absolute top-0 right-0"
      :text="code"
      :title="$values.labels.sms_code"
      closable
    />
  </main-layout>
</template>

<script>
import { computed, ref, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import LoginForm from '../components/LoginForm.vue'
import MainLayout from '../components/MainLayout.vue'

import { useAuthStore } from '../stores/auth'
import { useValues } from '../utils'

export default {
  components: { LoginForm, MainLayout },
  setup() {
    const router = useRouter()
    const auth = useAuthStore()
    const $values = useValues()

    const accessToken = computed(() => auth.accessToken)
    const requestCodeAt = computed(() => auth.publicTokenAt)
    const code = computed(() => auth.code)
    const hasPublicToken = computed(() => !!auth.publicToken)
    const shouldDisplayCode = computed(() => !!auth.publicToken && $values.environment.APP_ENV === 'local')

    const credentials = ref({})
    const messages = ref({})

    watch(accessToken, x => x && router.push({ name: 'agreement' }))

    onMounted(() => {
      window.ci_commit = process.env.CI_COMMIT_REF_NAME + '/' + process.env.CI_COMMIT_SHA
    })

    return {
      requestCodeAt,
      credentials,
      messages,

      code,
      hasPublicToken,
      shouldDisplayCode,

      requestCode: () => auth.requestCode(credentials.value)
        .then(() => messages.value = {})
        .catch(({ messages: x }) => messages.value = x),

      sendCode: () => auth.sendCode(credentials.value)
        .then(() => messages.value = {})
        .catch(({ messages: x }) => messages.value = x)
    }
  }
}
</script>
