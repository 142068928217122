<template>
  <div>
    <!-- Description -->
    <p class="mb-2">
      <!-- Position -->
      <span>
        {{ $values.labels.step_line_description(modelValue, to) }}
      </span>

      <!-- Title -->
      <span v-if="label">
        {{ '.' }}&nbsp;{{ label }}
      </span>
    </p>

    <!-- Line -->
    <div class="flex space-x-2">
      <div
        v-for="item in items"
        :key="item"
        :class="{
          'h-2 rounded-full basis-full': true,
          'bg-gray-200': item > modelValue,
          'bg-accent-default': item <= modelValue
        }"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { range } from '../utils'

export default {
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    from: {
      type: Number,
      default: 0
    },
    to: {
      type: Number,
      default: 4
    },
    labels: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const items = range(props.from, props.to)

    const label = computed(() => props.labels[props.modelValue])

    return {
      items,
      
      label
    }
  }
}
</script>
