<template>
  <div class="space-y-2 font-serif">
    <p class="text-center">
      ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
    </p>
    <p class="font-bold">
      1. Общие положения
    </p>
    <p>
      1.1 Политика обработки персональных данных в АО «ГК ОСНОВА» (далее — Политика) определяет
      основные принципы, цели, условия и способы обработки персональных данных, перечни субъектов
      и обрабатываемых персональных данных, права субъектов персональных данных, а также
      реализуемые в АО «ГК ОСНОВА» (далее – Оператор) требования к защите персональных данных.
    </p>
    <p>
      1.2 Политика разработана с учетом требований Конституции Российской Федерации, законодательных
      и иных нормативных правовых актов Российской Федерации в области персональных данных.
    </p>
    <p>1.3 Настоящая Политика является общедоступной и подлежит размещению на Сайте.</p>
    <p class="font-bold">
      2. Основные термины и определения
    </p>
    <p>
      <span class="font-bold">Персональные данные</span> - любая информация, относящаяся к прямо или косвенно определенному
      или определяемому физическому лицу (субъекту персональных данных).
    </p>
    <p>
      <span class="font-bold">Оператор</span> - АО «ГК ОСНОВА», зарегистрированный по адресу: 142702, Московская обл., город
      Видное, рабочий поселок Бутово, территория жилой комплекс Бутово-Парк, д. 18, корп. 1, пом. 59
    </p>
    <p><span class="font-bold">Пользователь</span> – физическое лицо, использующее Сайт посредством сети Интернет.</p>
    <p>
      <span class="font-bold">Сайт</span> – это совокупность текстов, графических элементов, дизайна, изображений, программного
      кода, и иных результатов интеллектуальной деятельности, содержащихся в сети Интернет под
      доменным именем anketa.gk-osnova.ru
    </p>
    <p>
      <span class="font-bold">Обработка персональных данных</span> — любое действие (операция) или совокупность действий
      (операций), совершаемые с использованием средств автоматизации или без использования таких
      средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение,
      уточнение (обновление, изменение), извлечение, использование, передачу (распространение,
      предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных
      данных.
    </p>
    <p>
      <span class="font-bold">Автоматизированная обработка персональных данных</span> - обработка персональных данных с
      помощью средств вычислительной техники.
    </p>
    <p>
      <span class="font-bold">Информационная система персональных данных</span> - совокупность содержащихся в базах данных
      персональных данных и обеспечивающих их обработку информационных технологий и
      технических средств.
    </p>
    <p>
      <span class="font-bold">Распространение персональных данных</span> - действия, направленные на раскрытие персональных
      данных неопределенному кругу лиц.
    </p>
    <p>
      <span class="font-bold">Предоставление персональных данных</span> - действия, направленные на раскрытие персональных
      данных определенному лицу или определенному кругу лиц.
    </p>
    <p>
      <span class="font-bold">Блокирование персональных данных</span> - временное прекращение обработки персональных данных
      (за исключением случаев, если обработка необходима для уточнения персональных данных).
    </p>
    <p>
      <span class="font-bold">Уничтожение персональных данных</span> - действия, в результате которых становится невозможным
      восстановить содержание персональных данных в информационной системе персональных данных
      и (или) в результате которых уничтожаются материальные носители персональных данных.
    </p>
    <p>
      <span class="font-bold">Обезличивание персональных данных</span> - действия, в результате которых становится невозможным
      без использования дополнительной информации определить принадлежность персональных данных
      конкретному субъекту персональных данных.
    </p>

    <p class="font-bold">3. Принципы и цели обработки персональных данных</p>
    <p>3.1 Обработка персональных данных осуществляется Оператором на основе принципов:</p>

    <ul style="list-style: inside">
      <li>
        законности и справедливости целей и способов обработки персональных данных, соответствия
        целей обработки персональных данных целям, заранее определенным и заявленным при сборе
        персональных данных;
      </li>
      <li>
        соответствия объема и характера обрабатываемых персональных данных, способов обработки
        персональных данных целям обработки персональных данных;
      </li>
      <li>достоверности персональных данных, их достаточности для целей обработки,
        недопустимости обработки персональных данных избыточных по отношению к целям, заявленным при сборе персональных данных;
      </li>
      <li>
        недопустимости объединения созданных для несовместимых между собой целей баз данных,
        содержащих персональные данные;
      </li>
      <li>
        хранение персональных данных осуществляется в форме, позволяющей определить субъекта
        персональных данных, не дольше, чем этого требуют цели их обработки.
      </li>
    </ul>

    <p>3.2 Персональные данные Пользователей обрабатываются для следующих целей:</p>

    <ul style="list-style: inside">
      <li>идентификация Пользователя;</li>
      <li>
        исполнение договорных обязательств Оператора перед Пользователем и иными субъектами
        персональных данных;
      </li>
      <li>рассмотрение Пользователя в кандидаты на трудоустройство в АО «ГК ОСНОВА»;</li>
      <li>
        направление информации посредством телефонной связи и электронной почты, связанной с
        поиском работы, в том числе: предложений вакансий, приглашений на собеседования, информации
        о результатах собеседования, запрос рекомендаций.
      </li>
      <li>
        включение в кадровый резерв,
      </li>
      <li>
        проверки достоверности предоставленных сведений и благонадежности Пользователя. 
      </li>
    </ul>

    <p class="font-bold">
      4. Перечень персональных данных, подлежащих обработке
    </p>
    <p>
      4.1 Оператор может обрабатывать следующие персональные данные: фамилия, имя, отчество; дата
      рождения; место рождения; гражданство; номер личного мобильного телефона; паспортные данные: серия, номер,
      дата выдачи, государственный орган, выдавший документ; сведения о фактическом месте
      проживания и адрес регистрации; номер страхового свидетельства пенсионного страхования;
      идентификационный номер налогоплательщика; семейное положение, сведения о составе семьи;
      сведения об образовании и профессиональных сертификатах; сведения о знаниях иностранных языков; сведения о рекомендателях; сведения,
      содержащиеся в документах воинского учета; сведения о наличии водительского
      удостоверения, категория, серия, номер, дата выдачи, государственный орган, выдавший документ, наличии автомобиля; сведения о трудоспособности; сведения о факте
      наличия противопоказаний к выполнению работы, которую предполагает должность; сведения о
      трудовой деятельности; сведения о вхождении в состав учредителей (участников), генеральных директоров, бенефициарных
      (фактических) владельцев коммерческих структур (вид деятельности, фактический адрес, дата
      регистрации и прекращения деятельности); сведения о родственниках и знакомых,
      трудоустроенных у Оператора; сведения, собираемые посредством метрических программ (cookies).
    </p>

    <p class="font-bold">
      5. Условия обработки персональных данных
    </p>

    <p>5.1 Оператор производит обработку персональных данных при наличии хотя бы одного из этих условий:</p>

    <ul style="list-style: inside">
      <li>
        обработка персональных данных осуществляется с согласия субъекта персональных данных на
        обработку его персональных данных;
      </li>
      <li>
        обработка персональных данных необходима для достижения целей, предусмотренных
        международным договором Российской Федерации или законом, для осуществления и выполнения
        возложенных законодательством Российской Федерации на Оператора функций, полномочий и
        обязанностей;
      </li>
      <li>
        обработка персональных данных необходима для осуществления правосудия, исполнения судебного
        акта, акта другого органа или должностного лица, подлежащих исполнению в соответствии с
        законодательством Российской Федерации об исполнительном производстве;
      </li>
      <li>
        обработка персональных данных необходима для исполнения договора, стороной которого либо
        выгодоприобретателем или поручителем, по которому является субъект персональных данных, а
        также для заключения договора по инициативе субъекта персональных данных или договора, по
        которому субъект персональных данных будет являться выгодоприобретателем или поручителем;
      </li>
      <li>
        обработка персональных данных необходима для осуществления прав и законных интересов
        Оператора или третьих лиц либо для достижения общественно значимых целей при условии, что
        при этом не нарушаются права и свободы субъекта персональных данных;
      </li>
      <li>
        осуществляется обработка персональных данных, подлежащих опубликованию или обязательному
        раскрытию в соответствии с федеральным законом.
      </li>
    </ul>

    <p>
      5.2 Оператор может обрабатывать персональные данные следующими способами: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление и уничтожение, как с использованием средств автоматизации, так и без использования таковых.
    </p>

    <p class="font-bold">
      6. Права Пользователей (субъектов персональных данных)
    </p>
    <p>
      6.1 Пользователь имеет право на получение у Оператора информации, касающейся обработки его
      персональных данных, если такое право не ограничено в соответствии с федеральными законами.
    </p>
    <p>
      6.2 Пользователь вправе требовать от Оператора уточнения его персональных данных, их
      блокирования или уничтожения в случае, если персональные данные являются неполными,
      устаревшими, неточными или не являются необходимыми для заявленной цели обработки, а также
      принимать предусмотренные законом меры по защите своих прав.
    </p>    
    <p>
      6.3 Пользователь имеет право отозвать свое согласие на обработку персональных данных путем
      отправки письменного запроса в адрес Оператора, в том числе посредством электронной почты
      info@gk-osnova.ru.
    </p>    
    <p>
      6.4 Если Пользователь считает, что Оператор осуществляет обработку его персональных данных с
      нарушением требований Федерального закона от 27.07.2006 №152-ФЗ «О персональных данных»
      или иным образом нарушает его права и свободы, Пользователь вправе обжаловать действия или
      бездействие Оператора в Уполномоченный орган по защите прав субъектов персональных данных
      или в судебном порядке.
    </p>

    <p class="font-bold">
      7. Обеспечение безопасности персональных данных
    </p>

    <p>
      7.1 Безопасность персональных данных, обрабатываемых Оператором, обеспечивается реализацией
      правовых, организационных и технических мер, необходимых для обеспечения требований
      федерального законодательства в области защиты персональных данных.
    </p>    

    <p>
      7.2 Для предотвращения несанкционированного доступа к персональным данным Оператором
      применяются следующие организационно-технические меры (но не ограничиваясь):
    </p>

    <ul style="list-style: inside">
      <li>
        назначение должностных лиц, ответственных за организацию обработки и защиты персональных
        данных;
      </li>
      <li>ограничение состава лиц, допущенных к обработке персональных данных;</li>
      <li>
        ознакомление работников Оператора с требованиями федерального законодательства и
        нормативных документов Оператора по обработке и защите персональных данных;
      </li>
      <li>
        организация учета, хранения и обращения носителей, содержащих информацию с персональными
        данными;
      </li>
      <li>
        определение угроз безопасности персональных данных при их обработке, формирование на их
        основе моделей угроз;
      </li>
      <li>
        разработка на основе модели угроз системы защиты персональных данных;
      </li> 
      <li>проверка готовности и эффективности использования средств защиты информации;</li>
      <li>
        разграничение доступа пользователей (работников Оператора) к информационным ресурсам и
        программно-аппаратным средствам обработки информации;
      </li>
      <li>
        регистрация и учет действий пользователей (работников Оператора) информационных систем
        персональных данных;
      </li>
      <li>
        использование антивирусных средств и средств восстановления системы защиты персональных
        данных.
      </li>
    </ul>

    <p class="font-bold">
      8. Актуализация, исправление, удаление и уничтожение персональных данных, ответы на запросы субъектов на доступ к персональным данным
    </p>
    <p>
      8.1 В случае предоставления субъектом персональных данных фактов о неполных, устаревших,
      недостоверных или незаконно полученных персональных данных Оператор обязан внести
      необходимые изменения, уничтожить или блокировать их, а также уведомить о своих действиях
      субъекта персональных данных.
    </p>    
    <p>
      8.2 Уничтожение (обезличивание) персональных данных Субъекта производится в следующих случаях:
    </p>
    
    <ul style="list-style: inside">
      <li>
        по достижении целей их обработки или в случае утраты необходимости в их достижении в срок, не превышающий тридцати дней с момента достижения цели обработки персональных данных, если иное не предусмотрено договором, стороной по которому является субъект персональных данных, иным соглашением между Оператором и субъектом персональных данных (его представителем);
      </li>
      <li>
        в случае отзыва субъектом персональных данных согласия на обработку своих персональных данных в течение тридцати дней, если иной срок не предусмотрен договором или соглашением между Оператором и субъектом персональных данных;
      </li>
      <li>
        в случае выявления неправомерной обработки персональных данных в срок, не превышающий десяти рабочих дней с момента выявления такого случая;
      </li>
      <li>
        в случае истечения срока хранения персональных данных, определяемого в соответствии с законодательством Российской Федерации и организационно-распорядительными документами Оператора;
      </li>
      <li>
        в случае предписания уполномоченного органа по защите прав субъектов персональных данных, органов прокуратуры Российской Федерации или решения суда.
      </li>
    </ul>

    <p>
      8.3 Оператор обязан сообщить субъекту персональных данных или его представителю информацию об осуществляемой им обработке персональных данных такого субъекта по его запросу в течение тридцати дней с даты получения запроса.
    </p>
  </div>
</template>
