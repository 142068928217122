<template>
  <div>
    <v-table
      v-if="materials"
      fixed-header
      height="100%"
    >
      <!-- Header -->
      <thead>
        <tr>
          <th
            v-for="column in columns"
            :key="column.id"
          >
            {{ column.label }}
          </th>
        </tr>
      </thead>

      <!-- Body -->
      <tbody>
        <tr
          v-for="material in materials"
          :key="material.id"
          class="h-16"
          @click="itemClick(material)"
        >
          <!-- job_name -->
          <material-table-cell-label :value="getMaterialJobName(material)" />

          <!-- person_name, person_surname, person_patronymic, person_phone -->
          <material-table-cell-label>
            <template #default>
              <div class="space-y-2">
                <p>{{ getMaterialPersonPhone(material) }}</p>
              </div>
            </template>
          </material-table-cell-label>

          <!-- created_at -->
          <material-table-cell-label :value="getMaterialCreatedAt(material)" />

          <!-- completed_at -->
          <material-table-cell-label :value="getMaterialCompletedAt(material)" />

          <!-- actions -->
          <td>
            <div
              v-if="false"
              class="flex justify-end items-center"
            >
              <v-btn
                variant="plain"
                size="small"
                icon="mdi-file"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>

    <loading-overlay v-else />
  </div>
</template>

<script>
import { key, useValues, openBlobAsPdf } from 'utils'
import { getMaterialJobName, getMaterialPersonPhone, getMaterialCreatedAt, getMaterialCompletedAt } from 'models/material'
import { useManagementStore } from 'stores/management'

import LoadingOverlay from 'elements/LoadingOverlay'
import MaterialTableCellLabel from 'components/material/MaterialTableCellLabel'

export default {
  components: { LoadingOverlay, MaterialTableCellLabel },
  props: {
    materials: {
      type: Array,
      default: () => null
    }
  },
  emits: ['item-click'],
  setup(_, { emit }) {
    const { downloadMaterial } = useManagementStore()

    const $values = useValues()

    return {
      itemClick: item => emit('item-click', item),

      getMaterialJobName,
      getMaterialPersonPhone,
      getMaterialCreatedAt,
      getMaterialCompletedAt,

      downloadMaterial: material => downloadMaterial(material).then(r => openBlobAsPdf(r)),

      columns: [
        {
          id: key(),
          label: $values.labels.job_name
        },
        {
          id: key(),
          label: $values.labels.person
        },
        {
          id: key(),
          label: $values.labels.began_at
        },
        {
          id: key(),
          label: $values.labels.sent_at
        },
        {
          id: key()
        }
      ]
    }
  }
}
</script>
