import LoginPage from '../views/LoginPage.vue'
import AgreementPage from '../views/AgreementPage.vue'
import DprPage from '../views/DprPage.vue'
import MaterialPage from '../views/MaterialPage.vue'
import MaterialCompleted from '../views/MaterialCompleted.vue'

import ManagementLogin from '../views/management/ManagementLogin.vue'
import ManagementWorkspace from '../views/management/ManagementWorkspace.vue'
import ManagementUsers from '../views/management/ManagementUsers.vue'
import ManagementMaterials from '../views/management/ManagementMaterials.vue'
// import ManagementMaterial from '../views/management/ManagementMaterial.vue'

export default [
  { name: 'index', path: '/', redirect: { name: 'login' } },
  { name: 'login', path: '/login', component: LoginPage },
  { name: 'agreement', path: '/agreement', component: AgreementPage },
  { name: 'dpr', path: '/dpr', component: DprPage },
  { name: 'materials', path: '/materials', component: MaterialPage },
  { name: 'materials-completed', path: '/materials/completed', component: MaterialCompleted  },

  { name: 'management-login', path: '/management/login', component: ManagementLogin },

  { 
    name: 'management', 
    path: '/management', 
    component: ManagementWorkspace,
    redirect: { name: 'management-materials' },
    children: [
      { name: 'management-materials', path: '/management/materials', component: ManagementMaterials },
      // { name: 'management-material', path: '/management/materials/:materialId', component: ManagementMaterial, props: true },
      { name: 'management-users', path: '/management/users', component: ManagementUsers }
    ] 
  }
]
