<template>
  <div class="space-y-2">
    <!-- Phone -->
    <main-field
      v-model="value.phone"
      name="phone"
      label="phone"
      mask="+7 ### ###-##-##"
      :errors="messages"
    />

    <!-- Code -->
    <main-field 
      v-if="withCode"
      v-model="value.code"
      name="code"
      label="sms_code"
      :errors="messages"
    />

    <div
      v-if="withCode"
      class="flex justify-end"
    >
      <v-btn
        variant="plain"
        size="small"
        :disabled="!canRequestCodeAgain"
        @click="requestCode"
      >
        {{ requestCodeAgainLabel }}
      </v-btn>
    </div>

    <!-- Request code -->
    <v-btn
      v-if="!withCode"
      rounded="pill"
      color="primary"
      class="w-full"
      @click="requestCode"
    >
      {{ $values.labels.send }}
    </v-btn>

    <!-- Send code -->
    <v-btn
      v-if="withCode"
      rounded="pill"
      color="primary"
      class="w-full"
      @click="sendCode"
    >
      {{ $values.labels.continue }}
    </v-btn>
  </div> 
</template>

<script>
import { computed, onUpdated, reactive, ref, watch } from 'vue'
import { differenceInSeconds } from 'date-fns'
import { useValues } from '../utils'

import MainField from 'components/MainField'

export default {
  components: {
    MainField
  },
  props: {
    withCode: {
      type: Boolean,
      default: false
    },
    requestCodeAt: {
      type: [Date, Number],
      default: () => null
    },
    messages: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['request-code', 'send-code', 'update:model-value'],
  setup(props, { emit }) {
    const $values = useValues()

    const value = reactive({})

    const untilRequestCodeAt = ref(Math.abs(differenceInSeconds(props.requestCodeAt, Date.now())) || 0)
    const untilRequestCodeInterval = ref(null)

    const requestCodeAgainLabel = computed(() => untilRequestCodeAt.value >= 60 
      ? $values.labels.request_code_again 
      : $values.labels.request_code_again_through_seconds(60 - untilRequestCodeAt.value)
    )

    const canRequestCodeAgain = computed(() => untilRequestCodeAt.value >= 60)

    watch(value, () => emit('update:model-value', { ...value }), { immediate: true, deep: true })

    onUpdated(() => {
      props.requestCodeAt 
        && (untilRequestCodeInterval.value = setInterval(() => untilRequestCodeAt.value = Math.abs(differenceInSeconds(props.requestCodeAt, Date.now())) || 0, 1000))
    })

    return {
      value,

      requestCodeAgainLabel,
      canRequestCodeAgain,

      requestCode: () => emit('request-code'),
      sendCode: () => emit('send-code')
    }
  }
}
</script>
