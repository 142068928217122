export default {
  phone: 'Телефон',
  send: 'Отправить',
  continue: 'Продолжить',
  forceContinue: 'Продолжить без проверки',
  checkingFormData: 'Производится проверка. Ожидайте',
  checkInn: 'Проверить ИНН',
  checkPassport: 'Проверить паспорт',
  back: 'Назад',
  add: 'Добавить',
  remove: 'Удалить',
  complete: 'Завершить',
  forceComplete: 'Завершить без проверки',
  checkExpertise: 'Проверить данные',
  checkError: 'Во время проверки произошла ошибка.<br>Проверьте правильность введённых данных',
  connectionError: '',
  login: 'Логин',
  password: 'Пароль',
  person: 'Пользователь',
  created_at: 'Дата добавления',
  register_at: 'Дата регистрации',
  sent_at: 'Дата отправки',
  began_at: 'Дата начала заполнения',
  logout: 'Выйти',
  returnToHome: 'На главную',
  not_defined: 'Не указано',
  request_code_again: 'Запросить код повторно',
  request_code_again_through_seconds: seconds => `Запросить код повторно через ${seconds} секунд`,
  
  materials: 'Анкеты',
  users: 'Пользователи',

  auth: 'Авторизация',
  about_auth: 'Для заполнения анкеты введите свой номер мобильного телефона',
  about_required_sign: 'Поля обязательные к заполнению',
  sms_code: 'Код из СМС',

  agreement: 'Согласие на обработку персональных данных',
  about_agreement: 'Подтвердите для продолжения работы',
  accept_agreement: 'Подтверждаю свое согласие на обработку персональных данных',

  material: 'Анкета кандидата',
  job_name: 'Название вакансии',
  name: 'Имя',
  surname: 'Фамилия',
  patronymic: 'Отчество',
  person_place_of_birth: 'Место рождения (по паспорту)',
  person_gender: 'Пол',
  person_at: 'Дата рождения',
  citizenship: 'Гражданство',
  phone_additional: 'Телефон (для экстренной связи)',
  person_address_plan: 'Адрес регистрации (прописки)',
  person_address_plan_country: 'Адрес регистрации - страна',
  person_address_plan_region: 'Адрес регистрации - регион',
  person_address_plan_region_name: 'Адрес регистрации - область',
  person_address_plan_city: 'Адрес регистрации - город',
  person_address_plan_street: 'Адрес регистрации - улица',
  person_address_plan_house: 'Адрес регистрации - дом',
  person_address_plan_block: 'Адрес регистрации - корпус / строение',
  person_address_plan_flat: 'Адрес регистрации - № квартиры',
  person_address_plan_corps: 'Адрес регистрации - корпус',
  person_address_plan_building: 'Адрес регистрации - строение',
  person_address_fact: 'Адрес фактического проживания (пример г.Москва, ул.Осенняя, д.1, кв.1)',
  person_address_fact_country: 'Адрес фактического проживания - страна',
  person_address_fact_region: 'Адрес фактического проживания - регион',
  person_address_fact_region_name: 'Адрес фактического проживания - область',
  person_address_fact_city: 'Адрес фактического проживания - город',
  person_address_fact_street: 'Адрес фактического проживания - улица',
  person_address_fact_house: 'Адрес фактического проживания - дом',
  person_address_fact_block: 'Адрес фактического проживания - корпус / строение',
  person_address_fact_flat: 'Адрес фактического проживания - № квартиры',
  person_address_fact_corps: 'Адрес фактического проживания - корпус',
  person_address_fact_building: 'Адрес фактического проживания - строение',
  person_same_address: 'Адреса регистрации и фактический адрес проживания совпадают',
  passport_code: 'Серия и номер паспорта',
  passport_seria: 'Серия паспорта',
  passport_number: 'Номер паспорта',
  passport_at: 'Дата выдачи паспорта',
  passport_by: 'Кем выдан паспорт (как в паспорте)',
  passport_subdivision_code: 'Код подразделения выдавшего паспорт',
  document_sns: 'Номер СНИЛС',
  document_tin: 'Номер ИНН',
  document_tin_link: 'Узнать свой ИНН',

  check_inn: 'Проверить ИНН',
  check_passport: 'Проверить Паспорт',

  family_status: 'Семейное положение',
  family_member_type: 'Степень родства',
  family_member_name: 'Ф.И.О',
  family_member_at: 'Дата рождения',

  languages_name: 'Наименование',
  languages_status: 'Степень знания языка',

  background_name: 'Наименование учебного заведения',
  background_at: 'Год окончания',
  background_result: 'Квалификация по диплому / специализация / отсутствует',
  
  recommendation_position: 'Должность',
  recommendation_organization: 'Название организации',
  recommendation_contact: 'Телефон организации, email организации',

  military_status: 'Являетесь ли Вы военнообязанным(ой)?',
  military_position: 'Воинское звание',
  military_location: 'Где и когда проходили воинскую службу?',
  military_resistance: 'Если была отсрочка от службы в армии укажите причину',

  other_referer: 'Из каких источников узнали о вакансии?',
  other_driver_license: 'Есть ли у Вас водительское удостоверение?',
  other_driver_category: 'Укажите категорию',
  other_driver_seria: 'Укажите серию',
  other_driver_number: 'Укажите номер',
  other_driver_date: 'Укажите дату выдачи документа',
  other_driver_gos: 'Укажите государственный орган, выдавший документ',
  other_driver_car: 'Есть ли у Вас личный автомобиль?',
  other_disability: 'Имеется ли у Вас инвалидность?',
  other_health_restriction: 'Имеются ли у Вас какие-либо медицинские ограничения по здоровью?',

  other_work_status: 'Работаете ли вы по совместительству?',
  other_work_values: 'Добавьте описание',
  other_work_values_name: 'Укажите название организации и должность',

  other_owner_status: 'Являетесь (являлись) ли Вы руководителем / учредителем коммерческих структур?',
  other_owner_values: 'Добавьте описание',
  other_owner_values_name: 'Полное название организации',
  other_owner_values_activity: 'Вид деятельности',
  other_owner_values_address: 'Фактический адрес',
  other_owner_values_from: 'Дата регистрации',
  other_owner_values_to: 'Дата закрытия',
  other_owner_values_now: 'По настоящее время',

  other_nepotism_status: 'Кто-то из Ваших родственников и знакомых работает в ГК «ОСНОВА»?',
  other_nepotism_values: 'Добавьте описание',
  other_nepotism_values_name: 'Укажите Фамилию, Имя, Отчество',

  other_criminal_now: 'Состоите ли Вы под следствием?',
  other_criminal_past: 'Привлекались ли Вы к уголовной ответственности?',

  consent_gov_job_a: 'В случае если Я состоял(а) на государственной или муниципальной службе в должностях, включенных в перечни, установленные нормативными правовыми актами РФ, обязуюсь до заключения трудового договора уведомить представителя работодателя, ответственного за кадровый учёт.',
  consent_gov_job_b: 'Я уведомлен(а) о том, что несоблюдение обязанности по информированию работодателя о факте работы в течение двух лет, предшествующих заключению трудового договора, на государственной (муниципальной) службе в должностях, включенных в перечни, установленные нормативными правовыми актами РФ, повлечет прекращение трудового договора (п. 2 ст. 12 Федерального закона от 25.12.2008 №273-ФЗ).',
  consent_trusty: 'Я подтверждаю, что указанные мною данные являются в полном объеме достоверными и могут быть проверены.',

  about_job: 'Вакансия',
  about_person: 'Личные данные',
  about_document: 'Данные о документах',
  about_family: 'Семья',
  about_family_members: 'Ваши ближайшие родственники (муж / жена, дети, отец, мать, брат / сестра)',
  about_background: 'Образование',
  about_recommendation: 'Рекомендации',
  about_military: 'Воинская обязанность',
  about_other: 'Дополнительные сведения',
  about_consent: 'Дополнительное подтверждение',
  about_languages: 'Знание иностранных языков',

  step_line_description: (value, to) => `${value} из ${to} шагов`
}
