<template>
  <div class="flex justify-center items-center h-full absolute top-0 right-0">
    <v-btn
      class="translate-x-12"
      variant="plain"
      icon="mdi-close"
      @click="click"
    />
  </div>
</template>

<script>
export default {
  emits: ['click'],
  setup(_, { emit }) {
    return {
      click: e => emit('click', e)
    }
  }
}
</script>
