<template>
  <div class="space-y-2">
    <p class="text-xl font-bold">
      {{ $values.labels.about_job }}
    </p>

    <!-- Job name -->
    <main-field
      :model-value="modelValue.job_name"
      name="job_name"
      label="job_name"
      :errors="errors"
      :required="isRequiredField('job_name')"
      @update:model-value="x => updateField('job_name', x)"
    />

    <p class="text-xl font-bold">
      {{ $values.labels.about_person }}
    </p>

    <!-- Surname -->
    <main-field
      :model-value="modelValue.person_surname"
      name="person_surname"
      label="surname"
      :errors="errors"
      :required="isRequiredField('person_surname')"
      @update:model-value="x => updateField('person_surname', x)"
    />

    <!-- Name -->
    <main-field
      :model-value="modelValue.person_name"
      name="person_name"
      label="name"
      :errors="errors"
      :required="isRequiredField('person_name')"
      @update:model-value="x => updateField('person_name', x)"
    />

    <!-- Patronymic -->
    <main-field
      :model-value="modelValue.person_patronymic"
      name="person_patronymic"
      label="patronymic"
      :errors="errors"
      :required="isRequiredField('person_patronymic')"
      @update:model-value="x => updateField('person_patronymic', x)"
    />

    <!-- PlaceOfBirth -->
    <main-field
      :model-value="modelValue.person_place_of_birth"
      name="person_place_of_birth"
      label="person_place_of_birth"
      :errors="errors"
      :required="isRequiredField('person_place_of_birth')"
      @update:model-value="x => updateField('person_place_of_birth', x)"
    />

    <!-- Gender -->
    <main-select
      :model-value="modelValue.person_gender"
      name="person_gender"
      label="person_gender"
      :errors="errors"
      :required="isRequiredField('person_gender')"
      :items="$values.enums.person_gender"
      @update:model-value="x => updateField('person_gender', x)"
    />

    <div class="grid grid-cols-2 space-x-5">
      <!-- Person at -->
      <main-field
        :model-value="modelValue.person_at"
        type="date"
        name="person_at"
        label="person_at"
        :errors="errors"
        :required="isRequiredField('person_at')"
        @update:model-value="x => updateField('person_at', x)"
      />

      <!-- Citizenship -->
      <main-select
        :model-value="modelValue.person_citizenship"
        name="person_citizenship"
        label="citizenship"
        :errors="errors"
        :required="isRequiredField('person_citizenship')"
        :items="$values.enums.person_citizenship"
        @update:model-value="x => updateField('person_citizenship', x)"
      />
    </div>

    <v-divider
      class="mb-4 border-opacity-100"
    />

    <div class="grid grid-cols-2 space-x-5">
      <!-- Phone -->
      <main-field
        :model-value="modelValue.person_phone"
        name="person_phone"
        label="phone"
        :errors="errors"
        mask="+7 ### ###-##-##"
        :required="isRequiredField('person_phone')"
        @update:model-value="x => updateField('person_phone', x)"
      />

      <!-- Phone additional -->
      <main-field
        :model-value="modelValue.person_phone_additional"
        name="person_phone_additional"
        label="phone_additional"
        :errors="errors"
        mask="+7 ### ###-##-##"
        :required="isRequiredField('person_phone_additional')"
        @update:model-value="x => updateField('person_phone_additional', x)"
      />
    </div>

    <v-divider
      class="mb-4 border-opacity-100"
    />


    <!-- Address plan -->
    <main-select
      :model-value="modelValue.person_address_plan_region"
      name="person_address_plan_region"
      label="person_address_plan_region"
      :errors="errors"
      returnObject
      :required="isRequiredField('person_address_plan_region')"
      :items="$values.enums.regions"
      @update:model-value="x => updateField('person_address_plan_region', x)"
    />

    <div v-if="!addressPlanAdvance">
      <address-field
        :model-value="modelValue.person_address_plan"
        name="person_address_plan"
        label="person_address_plan"
        :errors="errors"
        :required="isRequiredField('person_address_plan')"
        @update:model-value="x => updateField('person_address_plan', x)"
      />
      <div class="text-sm" v-if="addressPlanError">
        Адрес не найден в классификаторе, вы можете <button class="inline-button" @click="resetAddressPlan">ввести его вручную</button>
      </div>
    </div>
    <div v-else>
      <div class="grid grid-cols-2 space-x-5">
        <main-field
          :model-value="modelValue.person_address_plan_country"
          name="person_address_plan_country"
          label="person_address_plan_country"
          :errors="errors"
          :required="isRequiredField('person_address_plan_country')"
          @update:model-value="x => updateField('person_address_plan_country', x)"
        />
        <main-field
          :model-value="modelValue.person_address_plan_region_name"
          name="person_address_plan_region_name"
          label="person_address_plan_region_name"
          :errors="errors"
          :required="isRequiredField('person_address_plan_region_name')"
          @update:model-value="x => updateField('person_address_plan_region_name', x)"
        />
      </div>
      <div class="grid grid-cols-2 space-x-5">
        <main-field
          :model-value="modelValue.person_address_plan_city"
          name="person_address_plan_city"
          label="person_address_plan_city"
          :errors="errors"
          :required="isRequiredField('person_address_plan_city')"
          @update:model-value="x => updateField('person_address_plan_city', x)"
        />
        <main-field
          :model-value="modelValue.person_address_plan_street"
          name="person_address_plan_street"
          label="person_address_plan_street"
          :errors="errors"
          :required="isRequiredField('person_address_plan_street')"
          @update:model-value="x => updateField('person_address_plan_street', x)"
        />
      </div>
      <div class="grid grid-cols-2 space-x-5">
        <main-field
          :model-value="modelValue.person_address_plan_house"
          name="person_address_plan_house"
          label="person_address_plan_house"
          :errors="errors"
          :required="isRequiredField('person_address_plan_house')"
          @update:model-value="x => updateField('person_address_plan_house', x)"
        />
        <main-field
          :model-value="modelValue.person_address_plan_block"
          name="person_address_plan_block"
          label="person_address_plan_block"
          :errors="errors"
          :required="isRequiredField('person_address_plan_block')"
          @update:model-value="x => updateField('person_address_plan_block', x)"
        />
      </div>
      <div class="grid grid-cols-2 space-x-5">
        <main-field
          :model-value="modelValue.person_address_plan_flat"
          name="person_address_plan_flat"
          label="person_address_plan_flat"
          :errors="errors"
          :required="isRequiredField('person_address_plan_flat')"
          @update:model-value="x => updateField('person_address_plan_flat', x)"
        />
      </div>
    </div>

    <!-- Address fact -->
    <main-checkbox
      :model-value="modelValue.person_same_address"
      name="person_same_address"
      :errors="errors"
      :required="isRequiredField('person_same_address')"
      class="with-top-label with-top-control"
      @update:model-value="x => updateField('person_same_address', x)"
    >
      <template #label>
        <div>
          <p style="padding-top: 8px;">{{ $values.labels.person_same_address }}</p>
        </div>
      </template>
    </main-checkbox>
    <div v-if="!addressFactAdvance">
      <address-field
        :model-value="modelValue.person_address_fact"
        name="person_address_fact"
        label="person_address_fact"
        :errors="errors"
        :required="isRequiredField('person_address_fact')"
        :disabled="hasPositiveStatus"
        @update:model-value="x => updateField('person_address_fact', x)"
      />
      <div class="text-sm" v-if="addressFactError">
        Адрес не найден в классификаторе, вы можете <button class="inline-button" @click="resetAddressFact">ввести его вручную</button>
      </div>
    </div>
    <div v-else>
      <div class="grid grid-cols-2 space-x-5">
        <main-field
          :model-value="modelValue.person_address_fact_country"
          name="person_address_fact_country"
          label="person_address_fact_country"
          :errors="errors"
          :required="isRequiredField('person_address_fact_country')"
          @update:model-value="x => updateField('person_address_fact_country', x)"
        />
        <main-field
          :model-value="modelValue.person_address_fact_region_name"
          name="person_address_fact_region_name"
          label="person_address_fact_region_name"
          :errors="errors"
          :required="isRequiredField('person_address_fact_region_name')"
          @update:model-value="x => updateField('person_address_fact_region_name', x)"
        />
      </div>
      <div class="grid grid-cols-2 space-x-5">
        <main-field
          :model-value="modelValue.person_address_fact_city"
          name="person_address_fact_city"
          label="person_address_fact_city"
          :errors="errors"
          :required="isRequiredField('person_address_fact_city')"
          @update:model-value="x => updateField('person_address_fact_city', x)"
        />
        <main-field
          :model-value="modelValue.person_address_fact_street"
          name="person_address_fact_street"
          label="person_address_fact_street"
          :errors="errors"
          :required="isRequiredField('person_address_fact_street')"
          @update:model-value="x => updateField('person_address_fact_street', x)"
        />
      </div>
      <div class="grid grid-cols-2 space-x-5">
        <main-field
          :model-value="modelValue.person_address_fact_house"
          name="person_address_fact_house"
          label="person_address_fact_house"
          :errors="errors"
          :required="isRequiredField('person_address_fact_house')"
          @update:model-value="x => updateField('person_address_fact_house', x)"
        />
        <main-field
          :model-value="modelValue.person_address_fact_block"
          name="person_address_fact_block"
          label="person_address_fact_block"
          :errors="errors"
          :required="isRequiredField('person_address_fact_block')"
          @update:model-value="x => updateField('person_address_fact_block', x)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useValues } from '../utils'
import MainField from '../components/MainField.vue'
import AddressField from '../components/AddressField.vue'
import MainCheckbox from '../components/MainCheckbox.vue'
import MainSelect from '../components/MainSelect.vue'

export default {
  components: { 
    MainField,
    MainSelect,
    AddressField,
    MainCheckbox
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    requiredFields: {
      type: Object,
      default: () => ({})
    },
    addressPlanError: {
      type: Boolean,
      default: false
    },
    addressFactError: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update-field'],
  setup(props, { emit }) {
    const $values = useValues()
    const hasPositiveStatus = computed(() => props.modelValue.person_same_address)
    const addressPlanAdvance = ref(false)
    const addressFactAdvance = ref(false)
    function updateField (k, v) {
      emit('update-field', [k, v])
    }
    return {
      hasPositiveStatus,
      addressPlanAdvance,
      addressFactAdvance,
      isRequiredField: k => !!props.requiredFields[k],
      updateField,
      resetAddressPlan: () => {
        addressPlanAdvance.value = true
        updateField('person_address_plan', '')
        emit('set-address-plan-advance')
      },
      resetAddressFact: () => {
        addressFactAdvance.value = true
        updateField('person_address_fact', '')
        emit('set-address-fact-advance')
      },
    }
  }
}
</script>
