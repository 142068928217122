<template>
  <main-layout>
    <p>Благодарим Вас за заполнение анкеты и предоставление информации</p>
  </main-layout>
</template>

<script>
import MainLayout from '../components/MainLayout.vue'

export default {
  components: {
    MainLayout
  },
  setup() {
    
  }
}
</script>
