<template>
  <div class="h-full">
    <material-table
      :materials="materials"
    />
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useManagementStore } from '../../stores/management'

import MaterialTable from 'components/material/MaterialTable.vue'

export default {
  components: { MaterialTable },
  setup() {
    const router = useRouter()

    const materials = ref()

    const { fetchMaterials } = useManagementStore()

    onMounted(() => {
      fetchMaterials().then(({ materials: x }) => materials.value = x)
    })

    return {
      materials,

      goToMaterial: material => router.push({ name: 'management-material', params: { materialId: material.id } })
    }
  }
}
</script>
