import { sendGet, sendGetForRaw, toUrlByApiV1 } from '.'

export const getMaterial = ({ id }) =>
  sendGet(toUrlByApiV1(`management/materials/${id}`))

export const getMaterials = () =>
  sendGet(toUrlByApiV1('management/materials'))

export const getMaterialsRequiredFields = () =>
  sendGet(toUrlByApiV1('management/materials/required-fields'))

export const getUsers = () =>
  sendGet(toUrlByApiV1('management/users'))

export const renderMaterialAsPdf = ({ id }) =>
  sendGetForRaw(toUrlByApiV1(`management/materials/${id}/render-as-pdf`))
