<template>
  <div class="space-y-2">
    <p class="text-xl font-bold mb-4">
      {{ $values.labels.about_family }}
    </p>

    <!-- Family status -->
    <main-select
      :model-value="modelValue.family_status"
      name="family_status"
      label="family_status"
      :items="$values.enums.family_statuses"
      :errors="errors"
      :required="isRequiredField('family_status')"
      @update:model-value="x => updateField('family_status', x)"
    />

    <v-divider
      class="mb-4 border-opacity-100"
    />

    <p class="text-xl font-bold mb-4">
      {{ $values.labels.about_family_members }}
    </p>

    <div class="space-y-2">
      <div
        v-for="(member, i) in modelValue.family_members"
        :key="member.id"
        class="flex space-x-4 relative"
      >
        <!-- Family member type -->
        <main-select 
          :model-value="member.type"
          class="basis-1/4"
          :name="`family_members.${i}.type`"
          label="family_member_type"
          :errors="errors"
          :required="isRequiredField('family_members.*.type')"
          :items="$values.enums.member_type"
          @update:model-value="x => updateNestedField('type', x, 'family_members', member)"
        />

        <!-- Family member name -->
        <main-field
          :model-value="member.name"
          class="basis-1/3"
          :name="`family_members.${i}.name`"
          label="family_member_name" 
          :errors="errors"
          :required="isRequiredField('family_members.*.name')"
          @update:model-value="x => updateNestedField('name', x, 'family_members', member)"
        />

        <!-- Family member at -->
        <main-field
          :model-value="member.at"
          type="date"
          class="basis-1/4"
          :name="`family_members.${i}.at`"
          label="family_member_at" 
          :errors="errors"
          :required="isRequiredField('family_members.*.at')"
          @update:model-value="x => updateNestedField('at', x, 'family_members', member)"
        />

        <!-- Remove -->
        <div class="flex justify-center items-center h-full absolute right-0">
          <v-btn
            v-if="modelValue.family_members.length > 1"
            class="translate-x-12"
            variant="plain"
            icon="mdi-close"
            @click="removeNestedItem('family_members', member)"
          />
        </div>
      </div>

      <!-- Append -->
      <v-btn
        class="w-full mb-6 mt-2"
        @click="appendNestedItem('family_members')"
      >
        {{ $values.labels.add }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { key } from '../utils'

import MainField from '../components/MainField.vue'
import MainSelect from '../components/MainSelect.vue'

export default {
  components: { MainField, MainSelect },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    requiredFields: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update-field', 'update-nested-field', 'append-nested-item', 'remove-nested-item'],
  setup(props, { emit }) {
    return {
      isRequiredField: k => !!props.requiredFields[k],
      updateField: (k, v) => emit('update-field', [k, v]),
      updateNestedField: (k, v, f, x) => emit('update-nested-field', [k, v, f, x]),
      removeNestedItem: (f, x) => emit('remove-nested-item', [f, x]),
      appendNestedItem: f => emit('append-nested-item', [f, { id: key() }])
    }
  }
}
</script>
