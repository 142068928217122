<template>
  <div class="space-y-2">
    <!-- other_referer -->
    <main-select
      :model-value="modelValue.other_referer"
      name="other_referer"
      label="other_referer"
      :errors="errors"
      :required="isRequiredField('other_referer')"
      :items="$values.enums.vacancy_source"
      @update:model-value="x => updateField('other_referer', x)"
    />

    <vertical-divider spaced />
    
    <!-- other_driver_license -->
    <main-select
      :model-value="modelValue.other_driver_license"
      name="other_driver_license"
      label="other_driver_license"
      :errors="errors"
      :items="$values.enums.boolean_labels"
      :required="isRequiredField('other_driver_license')"
      @update:model-value="x => updateLicense(x)"
    />

    <div class="grid grid-cols-2 space-x-5">
      <!-- other_driver_date -->
      <main-field
        :model-value="modelValue.other_driver_date"
        type="date"
        name="other_driver_date"
        label="other_driver_date"
        :disabled="!hasDriverLicense"
        :errors="errors"
        :required="isRequiredField('other_driver_date')"
        @update:model-value="x => updateField('other_driver_date', x)"
      />
      <!-- other_driver_category -->
      <main-field
        :model-value="modelValue.other_driver_category"
        name="other_driver_category"
        label="other_driver_category"
        :disabled="!hasDriverLicense"
        :errors="errors"
        :required="isRequiredField('other_driver_category')"
        @update:model-value="x => updateField('other_driver_category', x)"
      />
    </div>
    <div class="grid grid-cols-2 space-x-5">
      <!-- other_driver_seria -->
      <main-field
        :model-value="modelValue.other_driver_seria"
        name="other_driver_seria"
        label="other_driver_seria"
        :disabled="!hasDriverLicense"
        :errors="errors"
        :required="isRequiredField('other_driver_seria')"
        @update:model-value="x => updateField('other_driver_seria', x)"
      />
    
      <!-- other_driver_number -->
      <main-field
        :model-value="modelValue.other_driver_number"
        name="other_driver_number"
        label="other_driver_number"
        :disabled="!hasDriverLicense"
        :errors="errors"
        :required="isRequiredField('other_driver_number')"
        @update:model-value="x => updateField('other_driver_number', x)"
      />
    </div>
    
    <!-- other_driver_gos -->
    <main-field
      :model-value="modelValue.other_driver_gos"
      name="other_driver_gos"
      label="other_driver_gos"
      :disabled="!hasDriverLicense"
      :errors="errors"
      :required="isRequiredField('other_driver_gos')"
      @update:model-value="x => updateField('other_driver_gos', x)"
    />

    <vertical-divider spaced />

    <!-- other_driver_car -->
    <main-select
      :model-value="modelValue.other_driver_car"
      name="other_driver_car"
      label="other_driver_car"
      :errors="errors"
      :required="isRequiredField('other_driver_car')"
      :items="$values.enums.boolean_labels"
      @update:model-value="x => updateField('other_driver_car', x)"
    />

    <!-- other_disability -->
    <main-select
      :model-value="modelValue.other_disability"
      name="other_disability"
      label="other_disability"
      :errors="errors"
      :required="isRequiredField('other_disability')"
      :items="$values.enums.boolean_labels"
      @update:model-value="x => updateField('other_disability', x)"
    />

    <!-- other_health_restriction -->
    <main-select
      :model-value="modelValue.other_health_restriction"
      name="other_health_restriction"
      label="other_health_restriction"
      :errors="errors"
      :required="isRequiredField('other_health_restriction')"
      :items="$values.enums.boolean_labels"
      @update:model-value="x => updateField('other_health_restriction', x)"
    />

    <vertical-divider spaced />

    <!-- other_work_status -->
    <main-select
      :model-value="modelValue.other_work_status"
      name="other_work_status"
      label="other_work_status"
      :errors="errors"
      :required="isRequiredField('other_work_status')"
      :items="$values.enums.boolean_labels"
      @update:model-value="x => updateField('other_work_status', x)"
    />

    <!-- other_work_values -->
    <div
      v-if="modelValue.other_work_status === $values.enums.boolean_labels[0]"
      class="space-y-2"
    >
      <!-- items -->
      <div
        v-for="(item, i) in modelValue.other_work_values"
        :key="item.id"
        class="relative"
      >
        <!-- name -->
        <main-field
          :model-value="item.name"
          :name="`other_work_values.${i}.name`"
          label="other_work_values_name"
          :errors="errors"
          :required="isRequiredField('other_work_values.*.name')"
          @update:model-value="x => updateNestedField('name', x, 'other_work_values', item)"
        />

        <!-- remove -->
        <item-remove-button-as-icon
          v-if="otherWorkValuesCount > 1"
          @click="removeNestedItem('other_work_values', item)"
        />
      </div>

      <!-- append -->
      <item-append-button @click="appendNestedItem('other_work_values', item)" />
    </div>

    <vertical-divider spaced />

    <!-- other_owner_status -->
    <main-select
      :model-value="modelValue.other_owner_status"
      name="other_owner_status"
      label="other_owner_status"
      :errors="errors"
      :required="isRequiredField('other_owner_status')"
      :items="$values.enums.boolean_labels"
      @update:model-value="x => updateField('other_owner_status', x)"
    />

    <!-- other_owner_values -->
    <div
      v-if="modelValue.other_owner_status === $values.enums.boolean_labels[0]"
      class="space-y-2"
    >
      <!-- items -->
      <div
        v-for="(item, i) in modelValue.other_owner_values"
        :key="item.id"
        class="relative"
      >
        <!-- name -->
        <main-field
          :model-value="item.name"
          :name="`other_owner_values.${i}.name`"
          label="other_owner_values_name"
          :errors="errors"
          :required="isRequiredField('other_owner_values.*.name')"
          @update:model-value="x => updateNestedField('name', x, 'other_owner_values', item)"
        />

        <!-- activity -->
        <main-field
          :model-value="item.activity"
          :name="`other_owner_values.${i}.activity`"
          label="other_owner_values_activity"
          :errors="errors"
          :required="isRequiredField('other_owner_values.*.activity')"
          @update:model-value="x => updateNestedField('activity', x, 'other_owner_values', item)"
        />

        <!-- address -->
        <main-field
          :model-value="item.address"
          :name="`other_owner_values.${i}.address`"
          label="other_owner_values_address"
          :errors="errors"
          :required="isRequiredField('other_owner_values.*.address')"
          @update:model-value="x => updateNestedField('address', x, 'other_owner_values', item)"
        />

        <div class="grid grid-cols-2 space-x-5">
          <!-- from -->
          <main-field
            :model-value="item.from"
            type="date"
            :name="`other_owner_values.${i}.from`"
            label="other_owner_values_from"
            :errors="errors"
            :required="isRequiredField('other_owner_values.*.from')"
            @update:model-value="x => updateNestedField('from', x, 'other_owner_values', item)"
          />

          <!-- to -->
          <main-field
            :model-value="item.to"
            type="date"
            :name="`other_owner_values.${i}.to`"
            label="other_owner_values_to"
            :errors="errors"
            :required="isRequiredField('other_owner_values.*.to')"
            :disabled="item.now"
            @update:model-value="x => updateNestedField('to', x, 'other_owner_values', item)"
          />
        </div>

        <div class="grid grid-cols-2 space-x-5">
          <div />

          <!-- now -->
          <main-checkbox
            :model-value="item.now"
            :name="`other_owner_values.${i}.now`"
            label="other_owner_values_now"
            :errors="errors"
            :required="isRequiredField('other_owner_values.*.now')"
            :items="$values.enums.boolean_labels"
            @update:model-value="x => updateNestedField('now', x, 'other_owner_values', item)"
          />
        </div>

        <!-- remove -->
        <item-remove-button
          v-if="otherOwnerValuesCount > 1"
          @click="removeNestedItem('other_owner_values', item)"
        />
      </div>

      <!-- append -->
      <item-append-button @click="appendNestedItem('other_owner_values', item)" />
    </div>

    <vertical-divider spaced />

    <!-- other_nepotism_status -->
    <main-select
      :model-value="modelValue.other_nepotism_status"
      name="other_nepotism_status"
      label="other_nepotism_status"
      :errors="errors"
      :required="isRequiredField('other_nepotism_status')"
      :items="$values.enums.boolean_labels"
      @update:model-value="x => updateField('other_nepotism_status', x)"
    />

    <!-- other_nepotism_values -->
    <div
      v-if="modelValue.other_nepotism_status === $values.enums.boolean_labels[0]"
      class="space-y-2"
    >
      <!-- items -->
      <div
        v-for="(item, i) in modelValue.other_nepotism_values"
        :key="item.id"
        class="relative"
      >
        <!-- name -->
        <main-field
          :model-value="item.name"
          :name="`other_nepotism_values.${i}.name`"
          label="other_nepotism_values_name"
          :errors="errors"
          :required="isRequiredField('other_nepotism_values.*.name')"
          @update:model-value="x => updateNestedField('name', x, 'other_nepotism_values', item)"
        />

        <!-- remove -->
        <item-remove-button-as-icon
          v-if="otherNepotismValuesCount > 1"
          @click="removeNestedItem('other_nepotism_values', item)"
        />
      </div>

      <!-- append -->
      <item-append-button @click="appendNestedItem('other_nepotism_values', item)" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { key } from '../utils'

import MainField from '../components/MainField.vue'
import MainSelect from '../components/MainSelect.vue'
import MainCheckbox from '../components/MainCheckbox.vue'
import ItemRemoveButton from '../components/ItemRemoveButton.vue'
import ItemRemoveButtonAsIcon from '../components/ItemRemoveButtonAsIcon.vue'
import ItemAppendButton from '../components/ItemAppendButton.vue'
import VerticalDivider from '../elements/VerticalDivider.vue'

export default {
  components: { MainField, MainSelect, MainCheckbox, ItemRemoveButton, ItemRemoveButtonAsIcon, ItemAppendButton, VerticalDivider },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    requiredFields: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update-field', 'update-nested-field', 'append-nested-item', 'remove-nested-item'],
  setup(props, { emit }) {
    const otherWorkValuesCount = computed(() => props.modelValue.other_work_values?.length || 0)
    const otherOwnerValuesCount = computed(() => props.modelValue.other_owner_values?.length || 0)
    const otherNepotismValuesCount = computed(() => props.modelValue.other_nepotism_values?.length || 0)

    const hasDriverLicense = computed(() => props.modelValue.other_driver_license == 'Да')
    const updateField = (k, v) => emit('update-field', [k, v])

    return {
      otherWorkValuesCount,
      otherOwnerValuesCount,
      otherNepotismValuesCount,
      hasDriverLicense,
      updateField,
      updateLicense: x => { x ? updateField('other_driver_license', x) : updateField('other_driver_license', null) },

      isRequiredField: k => !!props.requiredFields[k],
      updateNestedField: (k, v, f, x) => emit('update-nested-field', [k, v, f, x]),
      removeNestedItem: (f, x) => emit('remove-nested-item', [f, x]),
      appendNestedItem: f => emit('append-nested-item', [f, { id: key() }])
    }
  }
}
</script>
