import { defineStore } from 'pinia'
import { getMaterial, renderMaterialAsPdf, getUsers, getMaterials, getMaterialsRequiredFields } from '../api/management'

export const useManagementStore = defineStore('management', () => {
  return {
    fetchMaterial: ({ id }) => getMaterial({ id }),
    fetchMaterials: () => getMaterials(),
    fetchMaterialsRequiredFields: () => getMaterialsRequiredFields(),
    fetchUsers: () => getUsers(),
    downloadMaterial: material => renderMaterialAsPdf({ id: material.id })
  }
})
